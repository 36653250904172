import { useState, useCallback } from 'react';

const useNotification = () => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');

  const handleOpenNotification = useCallback((message) => {
    setOpen(true);
    setMessage(message);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
    setMessage('');
  }, []);

  return {
    open,
    message,
    onClose: handleClose,
    notify: handleOpenNotification,
  };
};

export default useNotification;
