import React from 'react';

import { Backspace } from '@material-ui/icons';
import { ListItem } from 'design-system';
import { useIntl } from 'react-intl';

export const SearchError = ({ searchTerm, setSearchTerm, inputRef }: any) => {
  const { formatMessage } = useIntl();
  const noResults = (
    <>
      {formatMessage({ id: 'searchInstructions.noResultsFor' })} <b>'{searchTerm}'</b>
    </>
  );
  return (
    <ListItem
      button
      onClick={(event) => {
        event.preventDefault();
        setSearchTerm('');
        inputRef?.current?.focus();
      }}
      iconTooltipText={formatMessage({ id: 'searchInstructions.clear' })}
      icon={<Backspace />}
      text={noResults}
    />
  );
};
