import gql from 'graphql-tag';

export const LoyaltyCustomerFragment = gql`
  fragment LoyaltyCustomerFragment on LoyaltyUserDetails {
    activeSince
    customerId
    dob
    email
    isoCountryCode
    loyaltyTier
    name
    thLegacyCognitoId
  }
`;
