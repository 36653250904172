import gql from 'graphql-tag';

export const GetRemediationReasons = gql`
  query GetRemediationReasons($id: ID!) {
    RemediationReasons(id: $id) {
      reasons {
        reasonLabel
        reasonSlug {
          current
        }
      }
    }
  }
`;
