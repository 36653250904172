import gql from 'graphql-tag';

import { CartFragment, OrderFragment } from './fragments';

export const GetUserOrders = gql`
  query User(
    $email: String!
    $createdAfter: String
    $createdBefore: String
    $limit: Int
    $resumeAfter: String
    $sortAscending: Boolean
  ) {
    Customer(email: $email) {
      cognitoId
      orders(
        createdAfter: $createdAfter
        createdBefore: $createdBefore
        limit: $limit
        resumeAfter: $resumeAfter
        sortAscending: $sortAscending
      ) {
        count
        orders {
          ...OrderFragment
          cart {
            ...CartFragment
          }
        }
      }
    }
  }
  ${CartFragment}
  ${OrderFragment}
`;
