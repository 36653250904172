import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((_theme) => {
  return {
    table: {
      tableLayout: 'fixed',
      '& th': {
        fontWeight: 'bold',
      },
      '& th, td': {
        lineHeight: 1.6,
      },
      '& tbody tr:last-child th, tbody tr:last-child td ': {
        borderBottom: 'unset',
      },
    },
  };
});
