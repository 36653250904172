import gql from 'graphql-tag';

import {
  INTLLoyaltyTransactionFragmentLegacy,
  INTLLoyaltyTransactionFragmentForTimeline,
  INTLLoyaltyTransactionFragmentForDetailView,
  INTLLoyaltyTransactionFragmentForSingleOrder,
} from './fragments';

// Used for legacy loyalty tab view
export const GetINTLLoyaltyTransactionsLegacy = gql`
  query intlLoyaltyTransactions($loyaltyId: String!) {
    intlLoyaltyTransactions(loyaltyId: $loyaltyId) {
      ...INTLLoyaltyTransactionFragmentLegacy
    }
  }
  ${INTLLoyaltyTransactionFragmentLegacy}
`;

// Used for showing loyalty transactions on the orders timeline and related information mentioned below
// For a scan and pay order, fetches total, currency, points earned, points used and created date
// For a support transaction, fetches points earned, support agent email and created date
export const GetINTLLoyaltyTransactionsForTimeline = gql`
  query intlLoyaltyTransactions($loyaltyId: String!, $resumeAfter: String) {
    intlLoyaltyTransactions(loyaltyId: $loyaltyId, resumeAfter: $resumeAfter) {
      ...INTLLoyaltyTransactionFragmentForTimeline
    }
  }
  ${INTLLoyaltyTransactionFragmentForTimeline}
`;

// Used for showing all loyalty transactions associated to an user
// Displays the points used, as well as the point cost of each reward item
export const GetINTLLoyaltyTransactionsForCompleteTimeline = gql`
  query IntlLoyaltyTransactionsWithRewardItems(
    $loyaltyId: String!
    $country: String!
    $resumeAfter: String
  ) {
    intlLoyaltyTransactionsWithRewardItems(
      loyaltyId: $loyaltyId
      country: $country
      resumeAfter: $resumeAfter
    ) {
      transaction {
        ...INTLLoyaltyTransactionFragmentForTimeline
        loyaltyId
        status
      }
      redeemedItems {
        name
        pointCost
      }
    }
  }
  ${INTLLoyaltyTransactionFragmentForTimeline}
`;

// Used for showing all the details on single scan and pay order page
export const GetINTLLoyaltyTransaction = gql`
  query intlLoyaltyTransaction($loyaltyId: String!, $shortId: String!) {
    intlLoyaltyTransaction(loyaltyId: $loyaltyId, shortId: $shortId) {
      ...INTLLoyaltyTransactionFragmentForDetailView
    }
  }
  ${INTLLoyaltyTransactionFragmentForDetailView}
`;

// Used for showing promotions on single order page
export const GetINTLLoyaltyTransactionsForSingleOrder = gql`
  query intlLoyaltyTransactions($loyaltyId: String!) {
    intlLoyaltyTransactions(loyaltyId: $loyaltyId) {
      ...INTLLoyaltyTransactionFragmentForSingleOrder
    }
  }
  ${INTLLoyaltyTransactionFragmentForSingleOrder}
`;
