import { Chip, Card, CardHeader, Divider, TableBody, TableRow, TableCell } from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons';
import {
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
  TimelineDot,
} from '@material-ui/lab';

import DummyText from 'components/dummy-text';
import Table from 'components/table';

import { useStyles } from './styles';

const PlaceholderItem = () => {
  const classes = useStyles();
  return (
    <TimelineItem>
      <TimelineOppositeContent className={classes.oppositeContent}>
        <Chip className={classes.oppositeContentChip} label={<DummyText width={220} />} />
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot color="grey">
          <ArrowForward />
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent className={classes.content}>
        <Card variant="outlined">
          <CardHeader
            title={<DummyText />}
            subheader={<DummyText />}
            titleTypographyProps={{ variant: 'h6' }}
          />
          <Divider />

          <Table>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  <DummyText />
                </TableCell>
                <TableCell>
                  <DummyText />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  <DummyText />
                </TableCell>
                <TableCell>
                  <DummyText />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Card>
      </TimelineContent>
    </TimelineItem>
  );
};

export default PlaceholderItem;
