import gql from 'graphql-tag';

import { INTLLoyaltyUserFragment } from './fragments';

export const GetINTLLoyaltyUserById = gql`
  query intlLoyaltyUserById($loyaltyId: String!) {
    intlLoyaltyUserById(loyaltyId: $loyaltyId) {
      ...INTLLoyaltyUserFragment
    }
  }
  ${INTLLoyaltyUserFragment}
`;
