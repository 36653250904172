import gql from 'graphql-tag';

export const RemoveSecondaryIdentifier = gql`
  mutation intlLoyaltyRemoveSecondaryIdentifier(
    $loyaltyId: String!
    $identifier: String!
    $identifierType: String!
  ) {
    intlLoyaltyRemoveSecondaryIdentifier(
      loyaltyId: $loyaltyId
      identifier: $identifier
      identifierType: $identifierType
    ) {
      loyaltyId
      identifier
      identifierType
    }
  }
`;
