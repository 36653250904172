import { ReactNode } from 'react';

import { Table as MUITable, TableContainer } from '@material-ui/core';
import clsx from 'clsx';

import { useStyles } from './table.styles';

const Table = ({ children, className }: { children: ReactNode; className?: string }) => {
  const classes = useStyles();
  return (
    <TableContainer>
      <MUITable className={clsx(classes.table, !!className && className)}>{children}</MUITable>
    </TableContainer>
  );
};

export default Table;
