import { ReactNode, useCallback, useContext, useEffect, createContext } from 'react';

import { noop } from 'components/utils';
import { IOrder, ICartEntry } from 'types';
import { centsToDollars, serializeServiceMode } from 'utils';

import * as GraphQLTypes from '../../../services/graphql/src/generated/graphql';
import { BRAND } from '../../constants';
import useAuth from '../../hooks/use-auth';
import { CustomEventNames, EventTypes } from './constants';
import { init } from './init';
import { IRefundDetails, IRefundItem, IMParticleProduct } from './types';

const mapItemToMParticleProduct = ({
  sanityId,
  name,
  refundAmount,
  refundQuantity,
  type,
}: IRefundItem): IMParticleProduct => ({
  Name: name,
  Sku: sanityId,
  Price: ~~refundAmount,
  Quantity: ~~refundQuantity,
  Brand: BRAND,
  Category: type,
});

const mapCartEntryToMParticleProduct = ({
  sanityId,
  name,
  price,
  quantity,
  type,
}: ICartEntry | GraphQLTypes.CartEntries): IMParticleProduct => ({
  Name: name ?? '',
  Sku: sanityId ?? '',
  Price: price ?? 0,
  Quantity: quantity,
  Brand: BRAND,
  Category: type,
});

/**
 * Recursively loops over cart entries to flatten nested children entries.
 * Every subsequent invocation will require passing `result` as the second argument otherwise the accumulation will fail
 */
const flattenAndMapCartEntries = (
  cartEntries: (ICartEntry | GraphQLTypes.CartEntries)[],
  result: IMParticleProduct[] = [],
) => {
  cartEntries.forEach((entry) => {
    const mappedEntry = mapCartEntryToMParticleProduct(entry);
    result.push(mappedEntry);
    if (entry.children?.length) {
      const initialChildren: (ICartEntry | GraphQLTypes.CartEntries)[] = [];
      const passableChildren = [...entry.children].reduce((acc, child) => {
        if (child) {
          acc.push(child);
        }
        return acc;
      }, initialChildren);
      flattenAndMapCartEntries(passableChildren, result);
    }
  });

  return result;
};

export interface ILogCustomerDobChangedEventOptions {
  previousDob: string;
  newDob: string;
  customerId: string;
  customerEmail: string;
}

export interface ILogCustomerEmailChangedEventOptions {
  previousEmail: string;
  newEmail: string;
  customerId: string;
}

export interface ILogPersonalizedOfferAssignedEventOptions {
  email: string;
  customerId: string;
  loyaltyId: string;
  offerTemplateId: string;
  offerTemplateName: string;
  reason: string;
  comments?: string;
  orderId?: string;
}

interface IMParticleContext {
  /**
   * Log Refund event to mParticle
   */
  logRefundEvent(order: IOrder | GraphQLTypes.Order, refundDetails: IRefundDetails): void;
  updateUserIdentities(options: { email: string; customerId: string; callback: () => void }): void;
  logCustomerDobChangedEvent(options: ILogCustomerDobChangedEventOptions): void;
  logPersonalizedOfferAssigned(options: ILogPersonalizedOfferAssignedEventOptions): void;
  logCustomerEmailChangedEvent(options: ILogCustomerEmailChangedEventOptions): void;
}

const MParticleContext = createContext<IMParticleContext>({
  logRefundEvent: noop,
  updateUserIdentities: noop,
  logCustomerDobChangedEvent: noop,
  logPersonalizedOfferAssigned: noop,
  logCustomerEmailChangedEvent: noop,
});

/**
 * Provides mParticle utilities
 */
export const useMParticleContext = () => useContext(MParticleContext);

/**
 * Provides mParticle utilities
 */
export const MParticleProvider = (props: { children: ReactNode }) => {
  const { email: userEmail, cognitoId: supportAgentId } = useAuth();

  useEffect(() => {
    init();
  }, []);

  const logCustomerDobChangedEvent = ({
    previousDob,
    newDob,
    customerId,
    customerEmail,
  }: ILogCustomerDobChangedEventOptions) => {
    const logAction = () =>
      window.mParticle.logEvent(CustomEventNames.UPDATE_USER_ATTRIBUTES, EventTypes.Other, {
        'Previous DOB': previousDob,
        'New DOB': newDob,
        'Customer ID': customerId,
        'Performed By': userEmail,
      });

    updateUserIdentities({
      customerId,
      email: customerEmail,
      callback: logAction,
    });
  };

  const logCustomerEmailChangedEvent = ({
    previousEmail,
    newEmail,
    customerId,
  }: ILogCustomerEmailChangedEventOptions) => {
    const logAction = () =>
      window.mParticle.logEvent(CustomEventNames.UPDATE_USER_ATTRIBUTES, EventTypes.Other, {
        'Previous Email': previousEmail,
        'New Email': newEmail,
        'Customer ID': customerId,
        'Performed By': userEmail,
      });

    updateUserIdentities({
      customerId,
      email: previousEmail,
      callback: logAction,
    });
  };

  /**
   * Log Refund event to mParticle
   */
  const logRefundEvent = useCallback(
    (order: IOrder | GraphQLTypes.Order, refundDetails: IRefundDetails) => {
      const isPartialRefund = refundDetails.items?.length;
      const initialCartEntries: (ICartEntry | GraphQLTypes.CartEntries)[] = [];
      const passableCartEntries = [...(order.cart?.cartEntries ?? [])].reduce((acc, entry) => {
        if (entry) {
          acc.push(entry);
        }
        return acc;
      }, initialCartEntries);
      const products = isPartialRefund
        ? refundDetails.items.map(mapItemToMParticleProduct)
        : flattenAndMapCartEntries(passableCartEntries);

      const additionalAttrs = {
        'Service Mode': serializeServiceMode(order.cart.serviceMode),
        'Tax Amount': centsToDollars(refundDetails.tax),
        'Total Amount': centsToDollars(refundDetails.requestedAmountCents),
        'Transaction POS': order.posVendor,
        'Transaction RBI Cloud Order ID': order.rbiOrderId,
        'Restaurant ID': order.cart.storeId,
        'Restaurant Address': order.cart?.storeAddress?.addressLine1,
        'Restaurant City': order.cart?.storeAddress?.city,
        'Restaurant State/Province Name': order.cart?.storeAddress?.state,
        'Restaurant Postal Code': order.cart?.storeAddress?.zip,
        'Restaurant Country': order.cart?.storeAddress?.country,
        'Franchise Group Name': order.cart?.storeDetails?.franchiseGroupName,
        //Unique to refund
        'Purchase Date': order.createdAt,
        'Refund Reason': refundDetails.primaryReason,
        'Refund Secondary Reason': refundDetails.secondaryReason,
        'Customer ID': order.cart.userId,
      };

      const transactionAttributes = {
        Id: order.rbiOrderId,
        Revenue: centsToDollars(refundDetails.subtotal),
        Tax: centsToDollars(refundDetails.tax),
      };

      const logAction = () =>
        window.mParticle.eCommerce.logRefund(
          transactionAttributes,
          products,
          false,
          additionalAttrs,
        );

      updateUserIdentities({
        email: order?.cart?.userDetails?.email || '',
        customerId: order?.cart?.userId || '',
        callback: logAction,
      });
    },
    [],
  );

  const updateUserIdentities = ({
    email,
    customerId,
    callback,
  }: {
    email: string;
    customerId: string;
    callback: () => void;
  }) => {
    window.mParticle.Identity.login(
      { userIdentities: { email, customerid: customerId || '' } },
      callback,
    );
  };

  /**
   * Log Personalized Offer Asignment to mParticle
   */
  const logPersonalizedOfferAssigned = useCallback(
    ({
      email,
      customerId,
      loyaltyId,
      offerTemplateId,
      offerTemplateName,
      reason,
      comments,
    }: ILogPersonalizedOfferAssignedEventOptions) => {
      const logAction = () =>
        window.mParticle.logEvent(CustomEventNames.PERSONALIZED_OFFER_ASSIGNED, EventTypes.Other, {
          'Customer Email': email,
          'Customer ID': customerId,
          'Customer Loyalty ID': loyaltyId,
          'Offer Template ID': offerTemplateId,
          'Offer Template Name': offerTemplateName,
          'Remediation Reason': reason,
          'Support Agent ID': supportAgentId,
          'Support Agent Comments': comments,
        });

      updateUserIdentities({
        email,
        customerId,
        callback: logAction,
      });
    },
    [supportAgentId],
  );

  return (
    <MParticleContext.Provider
      value={{
        logRefundEvent,
        updateUserIdentities,
        logCustomerDobChangedEvent,
        logPersonalizedOfferAssigned,
        logCustomerEmailChangedEvent,
      }}
    >
      {props.children}
    </MParticleContext.Provider>
  );
};
