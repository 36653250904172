import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
  IntrospectionResultData,
} from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';

import { getConfiguredLink } from './links';
import introspectionQueryResultData from './utils/sanity-fragments.json';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: introspectionQueryResultData as IntrospectionResultData,
});

const getConfiguredCache = () => new InMemoryCache({ fragmentMatcher });

export const getClient = () =>
  new ApolloClient({ cache: getConfiguredCache(), link: getConfiguredLink() });
