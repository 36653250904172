import { Person, Stars, ShoppingCart, Store } from '@material-ui/icons';
import { ListItem } from 'design-system';
import { useIntl } from 'react-intl';

export const SearchInstructions = () => {
  const { formatMessage } = useIntl();
  return (
    <>
      <ListItem
        iconTooltipText={formatMessage({ id: 'searchInstructions.customer' })}
        icon={<Person />}
        text={formatMessage({ id: 'searchInstructions.customerEmailOrCognitoID' })}
      />
      <ListItem
        iconTooltipText={formatMessage({ id: 'searchInstructions.loyaltyID' })}
        icon={<Stars />}
        text={formatMessage({ id: 'searchInstructions.loyaltyID' })}
      />
      <ListItem
        iconTooltipText={formatMessage({ id: 'searchInstructions.order' })}
        icon={<ShoppingCart />}
        text={formatMessage({ id: 'searchInstructions.RBIOrderID' })}
      />
      <ListItem
        iconTooltipText={formatMessage({ id: 'searchInstructions.store' })}
        icon={<Store />}
        text={formatMessage({ id: 'searchInstructions.storeNumberSanityIDorAddress' })}
      />
    </>
  );
};
