import React from 'react';

import { useIntl } from 'react-intl';

import { getBrandConfigs } from 'config/get-brand-configs';

const Logo = () => {
  const { formatMessage } = useIntl();

  const { Logo } = getBrandConfigs(formatMessage);
  return <Logo />;
};

export default Logo;
