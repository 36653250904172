import { useContext, createContext, ReactNode } from 'react';

import { IconButton, Snackbar } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import useNotification from 'hooks/use-notification';

import { useStyles } from './notification.styles';

export const NotificationContext = createContext({ notify: (_message: string) => {} });
export const useNotificationContext = () => useContext(NotificationContext);

export function NotificationProvider({ children }: { children: ReactNode }) {
  const classes = useStyles();
  const { open, notify, message, onClose } = useNotification();

  return (
    <NotificationContext.Provider
      value={{
        notify,
      }}
    >
      {children}
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        autoHideDuration={6000}
        ContentProps={{ 'aria-describedby': 'notification', className: classes.snackbarContent }}
        message={<span>{message}</span>}
        onClose={onClose}
        open={open}
        action={[
          <IconButton key="close" aria-label="Close" color="inherit" onClick={onClose}>
            <Close />
          </IconButton>,
        ]}
      />
    </NotificationContext.Provider>
  );
}

export default NotificationContext.Consumer;
