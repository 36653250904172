import { ApolloClientProvider } from './state/apollo';
import { AuthProvider } from './state/auth';
import { IntlProvider } from './state/intl';
import { INTLLoyaltyProvider } from './state/intl-loyalty-legacy';
import { IssueLoyaltyProvider } from './state/issue-loyalty';
import { LocationProvider } from './state/location';
import { LoyaltyProvider } from './state/loyalty';
import { MParticleProvider } from './state/mparticle';
import { NotificationProvider } from './state/notification';
import { ParamsProvider } from './state/params';
import { ThemeProvider } from './state/theme';
import { UniversalSearchProvider } from './state/universal-search';

const providers = [
  IntlProvider,
  ThemeProvider,
  NotificationProvider,
  AuthProvider,
  ApolloClientProvider,
  LocationProvider,
  ParamsProvider,
  INTLLoyaltyProvider,
  LoyaltyProvider,
  IssueLoyaltyProvider,
  MParticleProvider,
  UniversalSearchProvider,
];

export default providers;
