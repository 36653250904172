import { ComponentType, Suspense, lazy, LazyExoticComponent, FC } from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';
import { RouteComponentProps, Router } from '@reach/router';
import { useIntl } from 'react-intl';

import Layout from 'components/layout';
import Loader from 'components/loader';
import ReduceProviders from 'components/reduce-providers';
import { getBrandConfigs } from 'config/get-brand-configs';
import { useAuthContext } from 'state/auth';

import providers from './providers';
import { routes } from './utils/routing';

import './index.css';

type LazyRoute = LazyExoticComponent<ComponentType<any>>;

const AdminPanel: LazyRoute = lazy(() => import('./containers/admin-panel'));
const IntlLoyalty: LazyRoute = lazy(() => import('./containers/intl-loyalty'));
const INTLLoyaltyTransaction: LazyRoute = lazy(
  () => import('./containers/intl-loyalty-transaction'),
);
const Customer: LazyRoute = lazy(() => import('./containers/customer'));
const Home: LazyRoute = lazy(() => import('./containers/home'));
const LoyaltyDetails: LazyRoute = lazy(() => import('./components/intl-loyalty-legacy/details'));
const LoyaltyHistory: LazyRoute = lazy(() => import('./components/intl-loyalty-legacy/history'));
const LoyaltyLegacy: LazyRoute = lazy(() => import('./containers/loyalty-legacy'));
const LoyaltySearch: LazyRoute = lazy(() => import('./components/intl-loyalty-legacy/search'));
const Offers: LazyRoute = lazy(() => import('./containers/offers'));
const Order: LazyRoute = lazy(() => import('./containers/order'));
const Orders: LazyRoute = lazy(() => import('./containers/orders'));
const PermissionPanel: LazyRoute = lazy(() => import('./containers/permission-panel'));
const SignIn: LazyRoute = lazy(() => import('./containers/sign-in'));
const StoreDetails: LazyRoute = lazy(() => import('./containers/store'));
const SupportHistory: LazyRoute = lazy(() => import('./containers/support-history'));

const ContentRouting = () => {
  const { formatMessage } = useIntl();
  const NotFound: FC<RouteComponentProps> = () => (
    <p>{formatMessage({ id: 'main.nothingHere' })}</p>
  );
  const { intlLoyaltyEnabled } = getBrandConfigs(formatMessage);
  const { isAuthenticated } = useAuthContext();

  const AuthenticatedRedirect: FC<RouteComponentProps> = () => {
    window.location.href = routes.default;
    return null;
  };

  return (
    <Suspense fallback={<Loader />}>
      {isAuthenticated() ? (
        <Router primary={false}>
          <Home path={routes.default} />
          <AuthenticatedRedirect path="/signin" />

          <Customer path={routes.customer} />
          <Customer path={`${routes.customer}/:customerId`} />

          <Orders path={routes.orders} />
          <Orders path={`${routes.customer}/:customerId${routes.orders}`} />
          <Order path={`${routes.customer}/:customerId${routes.orders}/:orderId`} />

          {intlLoyaltyEnabled && (
            <>
              <Offers path={routes.offers} key={routes.offers} />
              <Offers path={`${routes.customer}/:customerId${routes.offers}`} />
              <LoyaltyLegacy path={routes.loyaltyLegacy}>
                <LoyaltySearch path={routes.default} />
                <LoyaltyDetails path="/:loyaltyId" />
                <LoyaltyHistory path={`/:loyaltyId${routes.history}`} />
              </LoyaltyLegacy>
              <IntlLoyalty path={routes.intlLoyalty} />
              <IntlLoyalty path={`${routes.customer}/:customerId${routes.intlLoyalty}`} />
              <INTLLoyaltyTransaction
                path={`${routes.customer}/:customerId${routes.intlLoyaltyTransaction}/:transactionId`}
              />
            </>
          )}

          <StoreDetails path={routes.store} />
          <StoreDetails path={`${routes.store}/:storeId`} />

          <SupportHistory path={routes.supportHistory} />
          <SupportHistory path={`${routes.customer}/:customerId${routes.supportHistory}`} />
          <AdminPanel path={routes.adminPanel} />
          <PermissionPanel path={routes.permissionPanel} />
          <PermissionPanel path={`${routes.permissionPanel}/:roleList`} />
          <NotFound default />
        </Router>
      ) : (
        <SignIn />
      )}
    </Suspense>
  );
};

const App = () => {
  return (
    <ReduceProviders providers={providers}>
      <CssBaseline />
      <Layout>
        <ContentRouting />
      </Layout>
    </ReduceProviders>
  );
};

export default App;
