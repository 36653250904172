import React, { createContext, useContext } from 'react';

import useAuth from 'hooks/use-auth';

import { AuthProps, DefaultAuthProps } from './types';

export const AuthContext = createContext<AuthProps>(DefaultAuthProps);
export const useAuthContext = () => useContext(AuthContext);

export function AuthProvider({ children }: { children: JSX.Element }) {
  const value = useAuth();
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthContext.Consumer;
