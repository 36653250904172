import React, { useContext, createContext } from 'react';

import useLoyalty from 'hooks/loyalty';

export const LoyaltyContext = createContext();
export const useLocationContext = () => useContext(LoyaltyContext);

export const LoyaltyProvider = ({ children }) => {
  const { loading, error, cardDetails, getLoyaltyCardDetails } = useLoyalty();

  return (
    <LoyaltyContext.Provider
      value={{
        loading,
        error,
        cardDetails,
        getLoyaltyCardDetails,
      }}
    >
      {children}
    </LoyaltyContext.Provider>
  );
};

export default LoyaltyContext.Consumer;
