import { useCallback, useEffect, useState } from 'react';

import { isValid } from 'date-fns';
import { createIntl, createIntlCache } from 'react-intl';

import { LANGUAGE } from 'components/language-select/constants';
import { inferLanguageFromNavigator } from 'state/intl/infer-language-from-navigator';
import { IBillingAddress, ICreditCard } from 'types';

import { getMessagesForLanguage } from './get-messages-for-language';
import defaultMessage from './translations/en.json';
export const defaultLocale = 'en-US';

const useIntl = () => {
  const [localeLanguage, setLocaleLanguage] = useState(
    localStorage.getItem(LANGUAGE) || inferLanguageFromNavigator() || defaultLocale,
  );
  const [messages, setMessages] = useState<Record<string, string>>(defaultMessage);

  const setCurrentLocale = useCallback((newLocaleLanguage: string) => {
    localStorage.setItem(LANGUAGE, newLocaleLanguage);

    // todo: make sure the locale is valid so we dont end up with fr-US
    setLocaleLanguage(newLocaleLanguage);
    getMessagesForLanguage(newLocaleLanguage).then(setMessages);
  }, []);

  useEffect(() => {
    const storagedLaguage = localStorage.getItem(LANGUAGE);

    if (storagedLaguage) {
      getMessagesForLanguage(storagedLaguage).then(setMessages);
    }
  }, []);

  const cache = createIntlCache();

  const intl = createIntl(
    {
      locale: localeLanguage,
      messages,
    },
    cache,
  );

  const formattedDate = (date: Date, options?: Intl.DateTimeFormatOptions) => {
    if (!isValid(date)) {
      return 'Invalid Date';
    }
    return intl.formatDate(date, {
      ...options,
      weekday: 'short',
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });
  };

  const formattedTime = (date: Date, options?: Intl.DateTimeFormatOptions) => {
    if (!isValid(date)) {
      return 'Invalid Time';
    }
    return intl.formatTime(date, {
      ...options,
      hour: 'numeric',
      minute: 'numeric',
      timeZoneName: 'short',
    });
  };

  const formattedDateTime = (date: Date | string, options?: Intl.DateTimeFormatOptions) => {
    const cleanDate = typeof date === 'string' ? new Date(date) : date;

    return `${formattedDate(cleanDate, options)}, ${formattedTime(cleanDate, options)}`;
  };

  const shortDate = (date: Date, options: Intl.DateTimeFormatOptions = {}) => {
    if (!isValid(date)) {
      return 'Invalid Date';
    }
    return new Intl.DateTimeFormat('default', { ...options }).format(date);
  };

  const shortDateTime = (date: Date, options: Intl.DateTimeFormatOptions = {}) =>
    `${shortDate(date, options)} ${formattedTime(date, options)}`;

  const formatBillingAddress = ({ locality, region, streetAddress, unitNumber }: IBillingAddress) =>
    [streetAddress, unitNumber, locality, region].reduce(
      (acc, str) => (str ? `${acc ? `${acc} ` : acc}${str}` : acc),
      '',
    );

  const formatCreditCards = (creditCards: ICreditCard[]) =>
    creditCards.map(({ billingAddress, createdAt, expiryMonth, expiryYear, ...card }) => ({
      ...card,
      active: true,
      billingAddress: formatBillingAddress(billingAddress),
      createdAt: formattedDateTime(new Date(createdAt)),
      expiration: expiryMonth && expiryYear && `${expiryMonth}${expiryYear}`,
      postalCode: billingAddress.postalCode,
    }));

  return {
    localeLanguage,
    messages,
    setCurrentLocale,
    formattedDate,
    formattedTime,
    formattedDateTime,
    shortDate,
    shortDateTime,
    formatCreditCards,
  };
};

export default useIntl;
