import gql from 'graphql-tag';

import {
  LoyaltyCardFragment,
  LoyaltyCustomerFragment,
  LoyaltyTransactionFragment,
} from './fragments';

export const GetLoyaltyByTHLegacyCognitoId = gql`
  query loyaltyByTHLegacyCognitoId($thLegacyCognitoId: String!) {
    loyaltyByTHLegacyCognitoId(thLegacyCognitoId: $thLegacyCognitoId) {
      customer {
        ...LoyaltyCustomerFragment
      }
      loyaltyCard {
        ...LoyaltyCardFragment
        loyaltyTransactions {
          count
          transactions {
            ...LoyaltyTransactionFragment
          }
        }
      }
    }
  }
  ${LoyaltyCustomerFragment}
  ${LoyaltyCardFragment}
  ${LoyaltyTransactionFragment}
`;
