import gql from 'graphql-tag';

export const IssueLoyaltyReward = gql`
  mutation issueLoyaltyReward($cardId: String!, $customerId: String!) {
    issueLoyaltyReward(cardId: $cardId, customerId: $customerId) {
      succeeded
      visits
      rewards
    }
  }
`;
