import gql from 'graphql-tag';

export const IssueLoyaltyVisit = gql`
  mutation issueLoyaltyVisit($cardId: String!, $customerId: String!) {
    issueLoyaltyVisit(cardId: $cardId, customerId: $customerId) {
      succeeded
      visits
      rewards
    }
  }
`;
