import gql from 'graphql-tag';

/**
 * @param orderId optional order ID
 */
export const INTLLoyaltyDeductPoints = gql`
  mutation intlLoyaltyDeductPoints(
    $loyaltyId: String!
    $loyaltyUserCognitoId: String!
    $points: Int!
    $reason: String!
    $orderId: String
    $comments: String
  ) {
    intlLoyaltyDeductPoints(
      loyaltyId: $loyaltyId
      loyaltyUserCognitoId: $loyaltyUserCognitoId
      points: $points
      reason: $reason
      orderId: $orderId
      comments: $comments
    )
  }
`;
