import { ListItem as ListItemMUI, Tooltip, ListItemIcon, ListItemText } from '@material-ui/core';

import { useStyles } from './styles';
import { ListItemProps } from './types';

const ListItem = ({
  button,
  component,
  icon,
  suffixIcon,
  iconTooltipText,
  onClick,
  text,
  to,
}: ListItemProps) => {
  const classes = useStyles();
  const listItemProps: any = {};
  if (button) {
    listItemProps.button = true;
  }
  if (component) {
    listItemProps.component = component;
  }
  if (onClick) {
    listItemProps.onClick = onClick;
  }
  if (!onClick) {
    listItemProps.className = classes.noClickableItem;
  }
  if (to) {
    listItemProps.to = to;
  }
  return (
    <ListItemMUI {...listItemProps}>
      <Tooltip title={iconTooltipText}>
        <ListItemIcon>{icon}</ListItemIcon>
      </Tooltip>
      <ListItemText className={classes.listItemText}>{text}</ListItemText>
      <ListItemIcon>{suffixIcon}</ListItemIcon>
    </ListItemMUI>
  );
};

export { ListItem };
