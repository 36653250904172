import React, { FC, createContext, useContext } from 'react';

import useINTLLoyalty, { IINTLLoyaltyContext } from 'hooks/use-intl-loyalty';
import { IsoCountryCode2Char } from 'types';

const DEFAULT_VALUE: IINTLLoyaltyContext = {
  loyaltyMarket: IsoCountryCode2Char.US,
  setLoyaltyMarket: () => {},
  loading: false,
  error: '',
  issuePoints: () => Promise.resolve(undefined),
  fetchLoyaltyUserByEmail: () => Promise.resolve(undefined),
  fetchLoyaltyUserById: () => Promise.resolve(undefined),
  fetchLoyaltyTransactions: () => Promise.resolve([]),
};

export const INTLLoyaltyContext = createContext(DEFAULT_VALUE);
export const useINTLLoyaltyContext = () => useContext(INTLLoyaltyContext);

export const INTLLoyaltyProvider: FC = ({ children }) => {
  const data = useINTLLoyalty();
  return <INTLLoyaltyContext.Provider value={data}>{children}</INTLLoyaltyContext.Provider>;
};

export default INTLLoyaltyContext.Consumer;
