import gql from 'graphql-tag';

export const LoyaltyCardFragment = gql`
  fragment LoyaltyCardFragment on LoyaltyCard {
    barcode
    cardFormat
    cardId
    cardType
    details {
      availableRedemptions
      donationUpcharge
      numberOfTransactionsNeeded
      cardType
      points
      rewardsTier
    }
    name
  }
`;
