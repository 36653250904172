import { AwsRegion } from '@rbilabs/intl';
import config from 'config/config.json';
import { Brand } from 'types';

// TODO: add some validation that requires BRAND & ENVIRONMENT
export const BRAND: Brand = (process.env.REACT_APP_RBI_BRAND as Brand) || 'bk';

export const REGION: string = process.env.REACT_APP_RBI_AWS_REGION || AwsRegion.EU_CENTRAL_1;

// TODO: remove the split in the future.
// We previously specified environment as 'dev-th' instead of 'dev'
export const ENVIRONMENT: string = `${process.env.REACT_APP_RBI_ENV}`.split('-')[0];

const getEnvKeyOrDefault = (key: string): string => {
  return process.env[`REACT_APP_${key}`] || config[BRAND][REGION][ENVIRONMENT][key];
};

export const USER_POOL_CLIENT_ID: string = getEnvKeyOrDefault('USER_POOL_CLIENT_ID');

export const USER_POOL_ID: string = getEnvKeyOrDefault('USER_POOL_ID');

export const MPARTICLE_API_KEY: string = getEnvKeyOrDefault('RBI_MPARTICLE_KEY');

export const DOMAIN: string = `${ENVIRONMENT}-${BRAND}-admin`;
let PREFIX: string;

switch (REGION) {
  case AwsRegion.EU_CENTRAL_1:
    PREFIX = 'euc1-';
    break;
  case AwsRegion.AP_SOUTHEAST_1:
    PREFIX = 'apse1-';
    break;
  case AwsRegion.EU_WEST_3:
    PREFIX = 'euw3-';
    break;
  case AwsRegion.US_EAST_2:
    PREFIX = 'use2-';
    break;
  default:
    PREFIX = '';
    break;
}

export const GQL_URL: string =
  process.env.REACT_APP_GQL_URL || `https://${PREFIX}${DOMAIN}.rbictg.com/graphql`;
export const isProduction: boolean = ENVIRONMENT === 'prod';
export const USER_POOL_ENDPOINT: string = `https://cognito-idp.${REGION}.amazonaws.com/`;

export const SANITY_PROJECT_ID: string = getEnvKeyOrDefault('SANITY_PROJECT_ID');

// Our Dataset is always of the form ENV_BRAND except in test ENV where we always use 'automation'
export const SANITY_DATA_SET = process.env.REACT_APP_ADMIN_SANITY_DATASET;

export const MAX_LOYALTY_TRANSACTIONS_PER_PAGE = 100;

export const SHOW_DRIVER_INFO_FT = 'display-driver-card';
export const SHOW_COMPLETE_LOYALTY_HISTORY = 'display-complete-loyalty-timeline';
export const SHOW_EMAIL_EDIT_FT = 'enable-email-edit';
export const ENABLE_SEARCH_ASSIGNED_OFFERS = 'enable-search-assigned-offers';
export const DISPLAY_POINTS_BALANCE = 'display-points-balance';
