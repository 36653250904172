import { setContext } from 'apollo-link-context';
import { merge } from 'lodash-es';

import { getCurrentUserSession } from 'remote/auth';

export const withAuthToken = setContext(async (_, previousContext) => {
  const session = await getCurrentUserSession();
  if (!session) {
    return previousContext;
  }
  const headers = {
    authorization: `Bearer ${session.getIdToken().getJwtToken()}`,
  };

  return merge({ headers }, previousContext);
});
