import { useContext, createContext, ReactNode } from 'react';

import { useUniversalSearchFocus } from 'hooks/use-universal-search';

import { UniversalSearchContextType } from './types';

const defaultSearchContext: UniversalSearchContextType = {
  focused: false,
  setFocused: () => {},
};

export const UniversalSearchContext = createContext(defaultSearchContext);
export const UseUniversalSearchContext = () => useContext(UniversalSearchContext);

export const UniversalSearchProvider = ({ children }: { children: ReactNode }) => {
  const { focused, setFocused } = useUniversalSearchFocus();

  return (
    <UniversalSearchContext.Provider
      value={{
        focused,
        setFocused,
      }}
    >
      {children}
    </UniversalSearchContext.Provider>
  );
};

export default UniversalSearchContext.Consumer;
