import { MouseEventHandler } from 'react';

import { IconButton } from '@material-ui/core';
import { KeyboardArrowUp, KeyboardArrowDown } from '@material-ui/icons';

import { useStyles } from './expand-row-button.styles';

const ExpandRowButton = ({
  className,
  disabled = false,
  expanded,
  onClick,
}: {
  className?: string;
  disabled?: boolean;
  expanded: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
}) => {
  const classes = useStyles();
  return (
    <span className={className}>
      <IconButton
        aria-label="expand row"
        size="small"
        onClick={onClick}
        className={classes.iconButton}
        disabled={!!disabled}
      >
        {expanded ? <KeyboardArrowUp fontSize="small" /> : <KeyboardArrowDown fontSize="small" />}
      </IconButton>
    </span>
  );
};

export default ExpandRowButton;
