import {
  Person,
  StoreOutlined,
  Phone,
  VerifiedUser,
  ShoppingCartOutlined,
} from '@material-ui/icons';
import { capitalize } from 'lodash-es';

import { nameOfMostExpensiveCartEntry } from 'components/utils';
import { routes } from 'utils/routing';

import { SearchResult } from './types';

export const searchResultDisplayMap = {
  BaseCustomer: {
    icon: (_result: SearchResult) => <Person data-testid="loyalty-customer-search-icon" />,
    tooltip: (_result: SearchResult) => 'Customer',
    route: (result: SearchResult) => `${routes.customer}/${result.id}`,
    display: (result: SearchResult) =>
      'email' in result && `(${result.isoCountryCode})${result.email}`,
  },
  CustomerDetails: {
    icon: (_result: SearchResult) => <Person data-testid="email-customer-search-icon" />,
    tooltip: (_result: SearchResult) => 'Customer',
    route: (result: SearchResult) => `${routes.customer}/${result.id}`,
    display: (result: SearchResult) =>
      'email' in result && `(${result.isoCountryCode})${result.email}`,
  },
  CustomerInfo: {
    icon: (_result: SearchResult) => <Phone data-testid="phone-customer-search-icon" />,
    suffixIcon: (result: SearchResult) =>
      'details' in result &&
      result?.details?.phoneVerified && (
        <VerifiedUser data-testid="verified-phone-customer-search-icon" />
      ),
    tooltip: (_result: SearchResult) => 'Customer',
    route: (result: SearchResult) =>
      'details' in result && `${routes.customer}/${result?.details?.customerId}`,
    display: (result: SearchResult) =>
      'details' in result && (result?.details?.email || result?.details?.name),
  },
  Order: {
    icon: (_result: SearchResult) => <ShoppingCartOutlined data-testid="order-search-icon" />,
    tooltip: (_result: SearchResult) => 'Order',
    route: (result: SearchResult) =>
      'userId' in result && `${routes.customer}/${result.userId}${routes.orders}/${result.id}`,
    display: (result: SearchResult) => 'cart' in result && nameOfMostExpensiveCartEntry(result),
  },
  Store: {
    icon: (_result: SearchResult) => <StoreOutlined data-testid="store-search-icon" />,
    tooltip: (result: SearchResult) => `Store ${'storeNumber' in result && result.storeNumber}`,
    route: (result: SearchResult) => `${routes.store}/${result.id}`,
    display: (result: SearchResult) =>
      'storeAddress' in result &&
      'storeNumber' in result &&
      [result.storeAddress?.address1, result.storeAddress?.city]
        .filter(Boolean)
        .join(', ')
        .toLowerCase()
        .split(' ')
        .map((word) => capitalize(word))
        .join(' '),
  },
};
