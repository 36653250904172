import { FC } from 'react';

import useAuthRedirects from 'hooks/use-auth-redirect';
import { useAuthContext } from 'state/auth';

const AuthRequired: FC = ({ children }) => {
  const { isAuthenticated } = useAuthContext();

  useAuthRedirects();

  return isAuthenticated() ? <>{children}</> : null;
};

export default AuthRequired;
