import { AwsRegion } from '@rbilabs/intl';

import { LANGUAGES } from './types';

export const READABLE_LANGUAGES = {
  [LANGUAGES.en]: 'English',
  [LANGUAGES.es]: 'Español',
  [LANGUAGES.pt]: 'Português',
};

export const LOCALES = {
  [LANGUAGES.en]: 'en_US',
  [LANGUAGES.es]: 'es_ES',
  [LANGUAGES.pt]: 'pt_PT',
};

export const AVAILABLE_LANGUAGES_BY_REGION = {
  // For now, all the langues will be available for all AWS regions
  [AwsRegion.EU_CENTRAL_1]: {
    ...READABLE_LANGUAGES,
  },
  [AwsRegion.AP_SOUTHEAST_1]: {
    ...READABLE_LANGUAGES,
  },
  [AwsRegion.EU_WEST_3]: {
    ...READABLE_LANGUAGES,
  },
  [AwsRegion.US_EAST_2]: {
    ...READABLE_LANGUAGES,
  },
};

export const LANGUAGE = 'RBI-SUPPORT::LANGUAGE';
