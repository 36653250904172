export function getNavigatorLanguage(): string {
  return (
    (window.navigator &&
      ((window.navigator.languages && window.navigator.languages[0]) ||
        window.navigator.language ||
        // @ts-ignore
        window.navigator.userLanguage)) ||
    ''
  );
}
