import gql from 'graphql-tag';

export const INTLLoyaltyOffers = gql`
  query intlLoyaltyOffersByLoyaltyId($loyaltyId: String!, $global: Boolean) {
    intlLoyaltyOffersByLoyaltyId(loyaltyId: $loyaltyId, global: $global) {
      id
      name
      sourceId
      createdAt
      metadata {
        source
        remediationReason
        comments
        agentEmail
        orderId
      }
      endDate
      type
      serviceModes
      configId
      errors {
        message
      }
    }
  }
`;
