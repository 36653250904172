import { useAuthContext } from 'state/auth';

import { Permission } from '../../../services/graphql/src/lib/users/roles-and-permissions';
const RestrictedComponent = ({
  children,
  restrictedComponent,
  accessPermission,
}: {
  children: React.ReactElement;
  restrictedComponent?: React.ReactElement;
  accessPermission: Permission;
}) => {
  const { permissions } = useAuthContext();
  return permissions.includes(accessPermission) ? children : restrictedComponent ?? null;
};

export default RestrictedComponent;
