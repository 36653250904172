import { getIso3, getSupportedCountries, IsoCountryCode3 } from '@rbilabs/intl';
import { Brand, IsoCountryCode } from '@rbilabs/intl-common';
import { Trie } from '../../structures/trie';

export interface IUser {
  email?: string;
  roles?: Role[];
  userName: string;
}

const businessRoles = ['supervisor', 'agent', 'compliance-agent', 'bot'];

/*
  This constant is of the form:
    databaseRoles = ['deu-admin', 'gbr-admin', 'zar-admin']
*/
const regionalRoles = getSupportedCountries()
  .map((iso2) => getIso3({ iso2 }))
  .filter((x): x is IsoCountryCode3 => !!x)
  .map((iso3) => `${iso3.toLowerCase()}-admin`);

const allRoles = [...businessRoles, ...regionalRoles];

const limits = ['points', 'refundAmount', 'refundDays'] as const;
export type Role = typeof allRoles[number];
export type LimitType = typeof limits[number];

/* eslint-disable no-shadow */
export enum Permission {
  // Business Permissions
  IssueLoyaltyPoints = 'RBI.supportActions.loyalty.issuePoints',
  RefundOrders = 'RBI.supportActions.orders.refund',
  CreateAccount = 'RBI.supportActions.accounts.create',
  AuditAccount = 'RBI.supportActions.accounts.auditAccount',
  IssueROTWPoints = 'RBI.supportActions.RUTW.issuePoints',
  ViewROTWCode = 'RBI.supportActions.RUTW.readPrizeCodes',
  BlockCustomer = 'RBI.compliance.customer.blockCustomer',
  //Database Superpermission
  ReadDB = 'RBI.database.read',
  WriteDB = 'RBI.database.write',
}

const getRegionalRolePermission = (iso3: string): string[] => {
  return [
    `${Permission.ReadDB}.${iso3.toString().toLowerCase()}`,
    `${Permission.WriteDB}.${iso3.toString().toLowerCase()}`,
  ];
};

const regionalPermissions = getSupportedCountries()
  .map((iso2) => getIso3({ iso2 }))
  .filter((x): x is IsoCountryCode3 => !!x)
  .reduce((acc, iso3) => {
    return [...acc, ...getRegionalRolePermission(iso3 as string)];
  }, [] as string[]);

const allPermissions = [...Object.values(Permission), ...regionalPermissions];

const getRoleToPermissionMapping = (brand: Brand) => {
  return getSupportedCountries({ brands: [brand] })
    .map((iso2) => getIso3({ iso2 }))
    .filter((x): x is IsoCountryCode3 => !!x)
    .reduce((acc, iso3) => {
      return { ...acc, [`${iso3.toLowerCase()}-admin`]: getRegionalRolePermission(iso3 as string) };
    }, {} as Record<string, string[]>);
};

const rolePermissionMapping: Record<Brand, Record<Role, string[]>> = {
  BK: {
    agent: [
      'RBI.supportActions.orders.*',
      'RBI.supportActions.loyalty.*',
      'RBI.supportActions.RUTW.*',
    ],
    bot: ['RBI.supportActions.loyalty.issuePoints', 'RBI.supportActions.orders.refund'],
    'compliance-agent': ['RBI.compliance.*'],
    supervisor: ['RBI.supportActions.*'],
    ...getRoleToPermissionMapping(Brand.BK),
  },
  FHS: {
    agent: [
      'RBI.supportActions.orders.*',
      'RBI.supportActions.loyalty.*',
      'RBI.supportActions.RUTW.*',
    ],
    bot: ['RBI.supportActions.loyalty.issuePoints', 'RBI.supportActions.orders.refund'],
    'compliance-agent': ['RBI.compliance.*'],
    supervisor: ['RBI.supportActions.*'],
    ...getRoleToPermissionMapping(Brand.FHS),
  },
  //  Do not update LTW Brand, this is a legacy and only relevant to the US markets
  LTW: {
    agent: [
      'RBI.supportActions.orders.*',
      'RBI.supportActions.loyalty.*',
      'RBI.supportActions.RUTW.*',
    ],
    bot: ['RBI.supportActions.loyalty.issuePoints', 'RBI.supportActions.orders.refund'],
    'compliance-agent': ['RBI.compliance.*'],
    supervisor: ['RBI.supportActions.*'],
  },
  PLK: {
    agent: [
      'RBI.supportActions.orders.*',
      'RBI.supportActions.loyalty.*',
      'RBI.supportActions.RUTW.*',
    ],
    bot: ['RBI.supportActions.loyalty.issuePoints', 'RBI.supportActions.orders.refund'],
    'compliance-agent': ['RBI.compliance.*'],
    supervisor: ['RBI.supportActions.*'],
    ...getRoleToPermissionMapping(Brand.PLK),
  },
  TH: {
    agent: ['RBI.supportActions.orders.*', 'RBI.supportActions.loyalty.*'],
    bot: ['RBI.supportActions.loyalty.issuePoints', 'RBI.supportActions.orders.refund'],
    'compliance-agent': ['RBI.compliance.*'],
    supervisor: ['RBI.supportActions.*'],
    ...getRoleToPermissionMapping(Brand.TH),
  },
};

//Limitation mapping, infinity means no limit
const roleLimitMapping: Record<Role, Record<LimitType, number>> = {
  agent: { points: Infinity, refundAmount: Infinity, refundDays: 30 },
  bot: { points: Infinity, refundAmount: Infinity, refundDays: 30 },
  supervisor: { points: Infinity, refundAmount: Infinity, refundDays: Infinity },
};

const permissionTrie = new Trie();

//Flattens the permissions to one flat list containing all user permissions.
export function getUserPermissions(roles: Role[]): string[] {
  const hasNoBusinessRoles = !roles.find((role) => businessRoles.includes(role));
  if (hasNoBusinessRoles) {
    // default role is bot if user has no business roles assigned.
    roles.push('bot');
  }
  const brand = (process.env.BRAND?.toUpperCase() ?? 'BK') as Brand;
  permissionTrie.addAll(allPermissions);
  let userPermissions: string[] = [];
  roles?.forEach((role) => {
    //Add all permissions from group to user
    rolePermissionMapping?.[brand]?.[role]?.forEach((permission) => {
      userPermissions = [
        ...userPermissions,
        ...permissionTrie.search(permission.replace('.*', '')),
      ];
    });
  });
  return [...new Set(userPermissions)];
}

//Gets the maximum limitations for a given user.
export function getUserLimits(roles: Role[]): Record<LimitType, number> {
  const userLimits: Record<LimitType, number> = { points: 0, refundAmount: 0, refundDays: 0 };
  for (const role of roles) {
    if (businessRoles.includes(role)) {
      for (const limit in roleLimitMapping[role] ?? []) {
        if (limit in userLimits) {
          userLimits[limit as LimitType] = Math.max(
            userLimits[limit as LimitType],
            roleLimitMapping[role][limit as LimitType],
          );
        }
      }
    }
  }
  return userLimits;
}

//Populates all the roles into a Trie object.
export function getPermissionTrie(): Trie {
  permissionTrie.addAll(allPermissions);
  return permissionTrie;
}

export function hasRegionalPermission({
  user,
  region,
}: {
  user: IUser;
  region: IsoCountryCode;
}): boolean {
  const userPermissions = getUserPermissions(user?.roles ?? []);
  return userPermissions.includes(Permission.ReadDB.valueOf() + `.${region.toLowerCase()}`);
}

export function hasBusinessPermission(user: IUser, role: Permission): boolean {
  const userPermissions = getUserPermissions(user.roles ?? []);
  const isAllowed = userPermissions.includes(role);
  return isAllowed;
}
