import gql from 'graphql-tag';

export const updateDobMutation = gql`
  mutation updateDob($customerId: String!, $dob: String!) {
    updateDob(customerId: $customerId, dob: $dob) {
      customerId
      dob
    }
  }
`;
