import React from 'react';

import { ApolloProvider } from '@apollo/react-hooks';

import { getClient } from './client';

export const ApolloClientProvider = ({ children }) => {
  const client = getClient();

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
