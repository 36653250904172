import { IntlFormatters } from 'react-intl';

import { BRAND } from '../constants';
import { getBkConfigs } from './bk-configs';
import { IBrandConfigs } from './brand-config';
import { getFhsConfigs } from './fhs-configs';
import { getPlkConfigs } from './plk-configs';
import { getThConfigs } from './th-configs';

/*
 * The idea behind this file is to keep all brand driven logic
 * together in one place so that we're not constantly pulling in
 * Brand and BRAND from utils/constants in order to conditionally
 * render different areas of the app
 */

export const getBrandConfigs = (formatMessage: IntlFormatters['formatMessage']): IBrandConfigs => {
  switch (BRAND) {
    case 'bk':
      return getBkConfigs(formatMessage);
    case 'plk':
      return getPlkConfigs(formatMessage);
    case 'th':
      return getThConfigs(formatMessage);
    case 'fhs':
      return getFhsConfigs(formatMessage);
    default:
      return getBkConfigs(formatMessage);
  }
};

export default getBrandConfigs;
