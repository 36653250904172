import { useContext, useEffect } from 'react';

import { navigate } from '@reach/router';

import { AuthContext } from 'state/auth';
import { useLocationContext } from 'state/location';
import { routes } from 'utils/routing';

export default function useAuthRedirects() {
  const { loading, isAuthenticated, needsReset } = useContext(AuthContext);
  const authenticated = isAuthenticated();
  const { location } = useLocationContext();
  useEffect(() => {
    if (loading) {
      return;
    }
    if (!needsReset && !authenticated && location.pathname !== routes.signIn) {
      navigate(routes.signIn, { replace: true });
    }
  }, [authenticated, loading, location.pathname, needsReset]);
}
