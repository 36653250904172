import gql from 'graphql-tag';

export const IssueLoyaltyPoints = gql`
  mutation issueLoyaltyPoints(
    $cardId: String!
    $customerId: String!
    $points: Int!
    $reason: String
    $comment: String
    $orderId: String
    $cognitoId: String
  ) {
    issueLoyaltyPoints(
      cardId: $cardId
      customerId: $customerId
      points: $points
      reason: $reason
      comment: $comment
      orderId: $orderId
      cognitoId: $cognitoId
    ) {
      points
      succeeded
    }
  }
`;
