import { makeStyles } from '@material-ui/core';

export const useNoDataStyles = makeStyles((theme) => ({
  box: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '180px',
    height: 'auto',
    alignItems: 'center',
    textAlign: 'justify',
  },
  placeholderIcon: {
    userSelect: 'none',
    fontSize: '200px',
    color: theme.palette.grey[300],
  },
}));
