// eslint-disable-next-line
export const EMAIL_REGEX = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i;

export const PHONE_NUMBER_REGEX = /^\+?\d{5,15}$/;

const comp2 = (left, right) => (...args) => left(right(...args));

export const identity = (x) => x;

export const compose = (...fns) => fns.reduce(comp2, identity);

export const isNull = (item) => item === null;

export const isUndefined = (item) => item === undefined;

export const isNil = (item) => isNull(item) || isUndefined(item);

export const isNumber = (item) => typeof item === 'number';

export const isFunction = (item) => item instanceof Function;

export * from './utils';
