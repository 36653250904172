import gql from 'graphql-tag';

export const GetCustomer = gql`
  query Customer($customerId: String!) {
    Customer(customerId: $customerId) {
      id: customerId
      customerId
      blockState {
        isBlocked
      }
      name
      createdAt
      deliveryAddresses {
        zip
        country
        route
        city
        streetNumber
        addressLine1
        addressLine2
        state
        alias
      }
      dob
      email
      isoCountryCode
      phoneNumber
      fdCustomerId
      defaultFdAccountId
      loyaltyId
      thLegacyCognitoId
      optedInLoyalty
      creditCards {
        createdAt
        alias
        cardType
        expiryMonth
        expiryYear
        billingAddress {
          postalCode
        }
      }
      secondaryIdentifiers {
        identifier
        identifierType
      }
    }
  }
`;

export const SearchCustomersByPhoneNumber = gql`
  query CustomersByPhoneNumber($phoneNumber: String!) {
    customersByPhoneNumber(phoneNumber: $phoneNumber) {
      details {
        customerId
        email
        name
        phoneVerified
      }
    }
  }
`;

export const CustomersByEmail = gql`
  query CustomersByEmail($email: String!) {
    customersByEmail(email: $email) {
      id: customerId
      email
      isoCountryCode
    }
  }
`;

export const CustomerByLoyaltyId = gql`
  query CustomerByLoyaltyId($loyaltyId: String!) {
    customerByLoyaltyId(loyaltyId: $loyaltyId) {
      id: customerId
      email
      isoCountryCode
    }
  }
`;

export const GetCustomerOrders = gql`
  query Customer($customerId: String!, $ordersResumeAfter: String) {
    Customer(customerId: $customerId) {
      id: customerId
      customerId # needed to query orders
      email
      isoCountryCode
      loyaltyId
      orders(resumeAfter: $ordersResumeAfter) {
        count
        orders {
          id: rbiOrderId
          createdAt
          cart {
            totalCents
            serviceMode
            storeAddress {
              country
            }
            storeDetails {
              timezone
            }
            payment {
              paymentType
            }
          }
          delivery {
            status
            dropoff {
              addressLine1
              addressLine2
              state
              city
            }
          }
          order {
            refund {
              approvedAmount
              refundedItems {
                name
              }
              adminUserEmail
            }
          }
          loyaltyTransaction {
            pointsEarned
            pointsBalance
            pointsUsed
            bonusPointsEarned
            rewardsUsed
          }
          paymentStatus
          paymentVersion
          status
        }
        resumeAfter
      }
      refundCounts {
        fullRefunds
        partialRefunds
        autoRefundable
        ordersForRefundPercentage
      }
    }
  }
`;

export const GetCustomerOrderStatusesByIds = gql`
  query Customer($customerId: String!, $orderIds: [String]!) {
    Customer(customerId: $customerId) {
      id: customerId
      customerId
      ordersByIds(orderIds: $orderIds) {
        orders {
          id: rbiOrderId
          delivery {
            status
          }
          paymentStatus
          status
        }
      }
    }
  }
`;

export const GetCustomerOffers = gql`
  query GetCustomerOffers($customerId: String!, $offersResumeAfter: String) {
    Customer(customerId: $customerId) {
      id: customerId
      customerId
      email
      isoCountryCode
      loyaltyId
      name
      assignedOffers(resumeAfter: $offersResumeAfter) {
        resumeAfter
        offers {
          agentEmail
          assignedDate
          challengeGoals
          challengeReward
          comments
          expiryDate
          id
          isActive
          loyaltyEngineId
          name
          offerTemplateId
          rbiOrderId
          reason
          redemptionDate
          redemptionSteps
          terms
          tokenId
        }
      }
      remediationReasons {
        label
        value
      }
      supportOffers {
        _id
        name
        configIncentive {
          loyaltyEngineId
        }
        loyaltyEngineId
        monetaryValueCents
        name
      }
    }
  }
`;

export const GetCustomerRemediations = gql`
  query Customer($customerId: String!, $remediationsResumeAfter: String) {
    Customer(customerId: $customerId) {
      id: customerId
      customerId # needed to query orders
      email
      isoCountryCode
      loyaltyId
      pointsRemediations {
        amount
        created
        displayName
        remediationReason
        remediationType
        remediationComments
        secondaryTransactionId
        secondaryTransactionType
        supportEmail
        transactionId
        transactionType
        userId
      }
      remediations(resumeAfter: $remediationsResumeAfter) {
        remediations {
          amount
          created
          displayName
          remediationComments
          remediationReason
          remediationType
          sanityId
          supportEmail
          transactionId
          transactionType
          userId
        }
        resumeAfter
      }
      remediationReasons {
        label
        value
      }
    }
  }
`;

export const GetCustomerSupportOffers = gql`
  query GetCustomerSupportOffers($customerId: String!) {
    Customer(customerId: $customerId) {
      id: customerId
      customerId
      email
      isoCountryCode
      loyaltyId
      remediationReasons {
        label
        value
      }
      supportOffers {
        _id
        name
        monetaryValueCents
        configIncentive {
          loyaltyEngineId
        }
        loyaltyEngineId
      }
      thLegacyCognitoId
      thLoyaltyCard {
        cardId
      }
    }
  }
`;
