import gql from 'graphql-tag';

export const updateCustomerBlockStateMutation = gql`
  mutation updateCustomerBlockState($customerId: String!, $isBlocked: Boolean!) {
    updateCustomerBlockState(customerId: $customerId, isBlocked: $isBlocked) {
      id: customerId
      customerId
      blockState {
        isBlocked
      }
      email
    }
  }
`;
