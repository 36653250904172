import { useState } from 'react';

import { useApolloClient } from '@apollo/react-hooks';

import {
  GetLoyaltyByEmail,
  GetLoyaltyByCustomerId,
  GetLoyaltyByTHLegacyCognitoId,
} from 'remote/queries';

const queryNames = {
  CUSTOMER_ID: 'loyaltyByCustomerId',
  EMAIL: 'loyaltyByEmail',
  COGNITO_ID: 'loyaltyByTHLegacyCognitoId',
};

const useLoyalty = () => {
  const client = useApolloClient();
  const [loading, setLoading] = useState(false);
  const [cardDetails, setCardDetails] = useState({});
  const [error, setError] = useState('');

  const getLoyaltyCardDetails = async ({ queryType, searchInput }) => {
    setLoading(true);
    setCardDetails({});

    const [query, queryName, variables, errorMsg] = {
      customerId: [
        GetLoyaltyByCustomerId,
        queryNames.CUSTOMER_ID,
        { customerId: searchInput },
        `Customer ID ${searchInput} not found.`,
      ],
      email: [
        GetLoyaltyByEmail,
        queryNames.EMAIL,
        { email: searchInput },
        `Customer email ${searchInput} not found.`,
      ],
      thLegacyCognitoId: [
        GetLoyaltyByTHLegacyCognitoId,
        queryNames.COGNITO_ID,
        { thLegacyCognitoId: searchInput },
        `Customer Legacy ID ${searchInput} not found.`,
      ],
    }[queryType];

    try {
      const { data, errors } = await client.query({ query, variables, errorPolicy: 'all' });
      const result = (data && data[queryName]) || {};
      if (!result.customer) {
        return setError(errorMsg);
      }
      setCardDetails(result);
      if (errors.length) {
        throw new Error('An error occurred querying for loyalty cards');
      }
      setError('');
    } catch {
      setError('There was an error while looking up the loyalty card.');
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    error,
    cardDetails,
    getLoyaltyCardDetails,
  };
};

export default useLoyalty;
