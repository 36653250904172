import { createContext, useContext, ReactNode } from 'react';

import { Location, NavigateFn, WindowLocation } from '@reach/router';

interface IDefaultContext {
  location: WindowLocation;
  navigate: NavigateFn;
}

const defaultContext: IDefaultContext = {
  location: window.location as WindowLocation,
  navigate: async () => {},
};

export const LocationContext = createContext(defaultContext);
export const useLocationContext = () => useContext(LocationContext);

export const LocationProvider = ({ children }: { children: ReactNode }) => {
  return (
    <Location>
      {({ location, navigate }) => (
        <LocationContext.Provider value={{ location, navigate }}>
          {children}
        </LocationContext.Provider>
      )}
    </Location>
  );
};

export default LocationContext.Consumer;
