import React, { createContext } from 'react';

import useIssueLoyalty from 'hooks/use-issue-loyalty';

export const IssueLoyaltyContext = createContext();

export const IssueLoyaltyProvider = ({ children }) => {
  const { loading, error, result, reset, issueLoyaltyHandler } = useIssueLoyalty();

  return (
    <IssueLoyaltyContext.Provider
      value={{
        loading,
        error,
        result,
        reset,
        issueLoyaltyHandler,
      }}
    >
      {children}
    </IssueLoyaltyContext.Provider>
  );
};

export default IssueLoyaltyContext.Consumer;
