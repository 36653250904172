import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
  return {
    wrapper: {
      display: 'flex',
    },
    text: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    buttonWrapper: {
      flex: 1,
    },
    iconButton: {
      marginInlineStart: theme.spacing(1),
      padding: theme.spacing(0.25),
    },
    icon: {
      fontSize: theme.typography.fontSize,
    },
  };
});
