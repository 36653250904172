import { useState } from 'react';

import { useApolloClient } from '@apollo/react-hooks';

import { capitalize } from 'components/utils';
import { IssueLoyaltyPoints, IssueLoyaltyReward, IssueLoyaltyVisit } from 'remote/queries';

const useIssueLoyalty = () => {
  const client = useApolloClient();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [result, setResult] = useState('');

  const issueLoyaltyHandler = async ({ cardId, customerId, loyaltyType, points }) => {
    const [loyaltyMutation, loyaltyVariables, mutationName] = {
      points: [
        IssueLoyaltyPoints,
        { cardId: cardId.replace(/-/g, ''), customerId, points },
        'issueLoyaltyPoints',
      ],
      reward: [IssueLoyaltyReward, { cardId, customerId }, 'issueLoyaltyReward'],
      visit: [IssueLoyaltyVisit, { cardId, customerId }, 'issueLoyaltyVisit'],
    }[loyaltyType];

    const errorType = capitalize(loyaltyType);

    // Issue points endpoint in the loyalty engine doesn't allow dashes

    setLoading(true);
    setError('');

    try {
      const { data } = await client.mutate({
        mutation: loyaltyMutation,
        variables: loyaltyVariables,
      });
      const result = (data && data[mutationName]) || {};
      result.succeeded ? setResult(result) : setError(`Loyalty ${errorType} was not issued`);
    } catch {
      setError(`There was an error while issuing the Loyalty ${errorType}`);
    } finally {
      setLoading(false);
    }
  };

  const reset = () => {
    setResult(null);
    setError('');
    setLoading(false);
  };

  return {
    loading,
    error,
    result,
    reset,
    setError,
    issueLoyaltyHandler,
  };
};

export default useIssueLoyalty;
