import {
  Avatar,
  Card,
  CardHeader,
  Chip,
  CircularProgress,
  Divider,
  TableBody,
} from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import DoneIcon from '@material-ui/icons/Done';
import {
  Timeline as TimelineMui,
  TimelineItem as TimelineItemMui,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
  TimelineDot,
} from '@material-ui/lab';
import { Link } from '@reach/router';

import OneColumnTableRow from 'components/one-column-table-row';
import Table from 'components/table';
import TwoColumnTableRow from 'components/two-column-table-row';

import PlaceholderItem from './placeholder-item';
import { useStyles } from './styles';
import { FinalItemType, TimeLineType, TimeLineItemType, TimeLineItemsType } from './types';

const TimeLine = ({
  timeLineItems,
  isLoading,
  isFetchingMoreItems,
  hasMoreToFetch,
}: TimeLineType) => {
  return (
    <TimelineMui>
      {!!timeLineItems && !isLoading
        ? timeLineItems.map((props, key: number) => <TimeLineItem {...props} key={key} />)
        : Array(5)
            .fill(null)
            .map((_, index) => <PlaceholderItem key={index} />)}
      <FinalItem
        isLoading={isLoading}
        isFetchingMoreItems={isFetchingMoreItems}
        noMoreItems={!hasMoreToFetch}
      />
    </TimelineMui>
  );
};

const TimeLineItem = ({
  action,
  details,
  dotIcon,
  isLoading,
  leftLink,
  leftText,
  leftIcon,
  title,
}: TimeLineItemsType) => {
  const classes = useStyles();
  return (
    <TimelineItemMui>
      <TimelineOppositeContent className={classes.oppositeContent}>
        {!!leftLink ? (
          <Chip
            component={Link}
            label={leftText}
            to={leftLink}
            className={`${classes.oppositeContentChip} ${classes.oppositeContentChipLink}`}
            avatar={<Avatar className={classes.oppositeContentAvatar}>{leftIcon}</Avatar>}
          />
        ) : (
          <Chip
            label={leftText}
            className={classes.oppositeContentChip}
            avatar={<Avatar className={classes.oppositeContentAvatar}>{leftIcon}</Avatar>}
          />
        )}
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot color="primary">
          {!!isLoading || !dotIcon ? <CircularProgress size={24} color="inherit" /> : dotIcon}
        </TimelineDot>
        <TimelineConnector className={classes.connector} />
      </TimelineSeparator>
      <TimelineContent className={classes.content}>
        <Card variant="outlined">
          <CardHeader title={title} titleTypographyProps={{ variant: 'h6' }} action={action} />
          <Divider />
          <Table>
            {details && (
              <TableBody>
                {details.map((detail: TimeLineItemType, key: number) =>
                  detail.headerText ? (
                    <TwoColumnTableRow {...detail} key={key} />
                  ) : (
                    <OneColumnTableRow {...detail} key={key} />
                  ),
                )}
              </TableBody>
            )}
          </Table>
        </Card>
      </TimelineContent>
    </TimelineItemMui>
  );
};

const FinalItem = ({ isFetchingMoreItems, isLoading, noMoreItems }: FinalItemType) => {
  const classes = useStyles();

  return (
    <TimelineItemMui>
      <TimelineOppositeContent className={classes.oppositeContent}>
        <div className={classes.oppositeContentChip} />
      </TimelineOppositeContent>
      <TimelineSeparator>
        {(noMoreItems && (
          <TimelineDot color="grey">
            <DoneIcon />
          </TimelineDot>
        )) ||
          ((isLoading || isFetchingMoreItems) && (
            <TimelineDot color="primary">
              <CircularProgress size={24} color="inherit" />
            </TimelineDot>
          )) || (
            <TimelineDot color="primary">
              <ArrowDownwardIcon />
            </TimelineDot>
          )}
      </TimelineSeparator>
      <TimelineContent className={classes.content}></TimelineContent>
    </TimelineItemMui>
  );
};

export { TimeLine, TimeLineItem };
