import { format, isValid, parse, subWeeks } from 'date-fns';
import { CountryCode, parsePhoneNumberFromString } from 'libphonenumber-js';

import { getIso3 } from '@rbilabs/intl';
import { IsoCountryCode, ISO_ISO2_MAPPING } from '@rbilabs/intl-common';
import { IsoCountryCodeToCurrencyNameMap } from '@rbilabs/intl-common';
import { OrderDetails } from 'components/order-details/types';

import { PAYMENT_VERSION_MAPPING } from '../../../services/graphql/src/constants';
import {
  REFUND_ERROR_NON_PAYMENT_V1_ORDER_STATUSES,
  REFUND_ERROR_PAYMENT_V1_STATUSES,
  REFUND_SUCCESS_NON_PAYMENT_V1_ORDER_STATUSES,
  REFUND_SUCCESS_PAYMENT_V1_STATUSES,
} from './constants';

export const currencyToIsoCountryCodeMap = Object.entries(IsoCountryCodeToCurrencyNameMap).reduce(
  (obj, [key, val]) => ({
    ...obj,
    [val]: key,
  }),
  {},
);

/* At this time, this is a complete mapping of every restaurant's 
physicalAddress.country in Sanity across all markets and stages .

We need this information to infer the currency of an order.

Since this app deals with historical records, 
we should never remove entries from this map, 
even once typos in MDS / Sanity are corrected.

As we encounter more historical typos, we will have to add them here.
We will also have to make an effort to fail gracefully, 
when stores have unexpected values in physicalAddress.country.

We can hopefully dispense with this hack,
once all orders in dynamo have a currency attribute,
or once a store's currency is available in Sanity. */

export enum SanityCountryNameToIsoCountryCodeMap {
  'South Africa ' = 'ZAF', // 🤦‍♂️
  'Swtizerland' = 'CHE', // 🤦‍♀️
  'US' = 'USA',
  'USA' = 'USA',
  'United Kingdom' = 'GBR',
  'United States' = 'USA',
  'United states' = 'USA', // 🤦
}

export const sanityCountryNameToIso3 = (countryName: string) => {
  const iso3 = getIso3({ countryName });

  if (iso3) {
    return iso3;
  }

  return SanityCountryNameToIsoCountryCodeMap[countryName];
};

export const formatBooleanToYesNo = (boolean: boolean) => (boolean ? 'Yes' : 'No');

export const formatPhoneNumber = (phoneNumber: string) => {
  return parsePhoneNumberFromString(phoneNumber)?.formatNational() ?? '';
};

export const formatPhoneNumberWCountryCode = (phoneNumber: string, isoCountryCode: string) => {
  const countryCode = isoCountryCode as CountryCode;
  return parsePhoneNumberFromString(phoneNumber, countryCode)?.formatNational() ?? '';
};

/* These should all eventually use react-intl methods */

export const lastWeeksDateInUTC = () => {
  const todaysDate = new Date();
  const lastWeeksDate = format(subWeeks(todaysDate, 1), 'yyyy-MM-dd hh:mm:ss');
  // reformat lastWeeksDate to support conversion ISOString on all browsers
  // Ex: 2022-08-03 04:06:11 is formatted to 2022/08/03 04:06:11
  const lastWeeksDateFormatted = lastWeeksDate.replace(/-/g, '/').replace('T', ' ');
  const lastWeeksDateInUTCFormat = new Date(lastWeeksDateFormatted).toISOString();
  return lastWeeksDateInUTCFormat;
};

export const longDate = (date: Date) => format(date, 'MMMM d, yyyy');

export const longDateFromYYYYMMDDString = (YYYYMMDD: string) => {
  const date = parse(YYYYMMDD, 'yyyy-MM-dd', new Date());
  if (!isValid(date)) {
    return null;
  }
  return longDate(date);
};

export const todaysDateToYYYYMMDDString = () => {
  return format(new Date(), 'yyyy-MM-dd');
};

export const formattedShortTime = (date: string) => {
  if (!date) {
    return null;
  }

  return format(new Date(date), 'h:mmaaa');
};

export const formattedTimeFromHHMMSSString = (HHMMSS: string) => {
  const date = parse(HHMMSS, 'HH:mm:ss', new Date());
  if (!isValid(date)) {
    return null;
  }
  return format(date, 'h:mmaaa');
};

export const localYYYYMMDDFromUTCYYYMMDD = (
  dateString: string,
  { timeZone }: { timeZone: string },
) => {
  const date = new Date(dateString);
  if (!isValid(date)) {
    return '';
  }
  // en-CA formats dates as YYYY-MM-DD
  try {
    return new Intl.DateTimeFormat('en-CA', { timeZone }).format(date);
  } catch {
    return new Intl.DateTimeFormat('en-CA', { timeZone: 'UTC' }).format(date);
  }
};

export const formatMoney = (cents: number) => (cents / 100).toFixed(2);
export const centsToDollars = (price: number = 0) => {
  return price / 100;
};

export const currencyName = (countryCode: string = '') => {
  return (
    IsoCountryCodeToCurrencyNameMap[countryCode] ||
    /**
     * adding an extra validation since for some countries, instead of getting the IsoCountryCode,
     * we are getting the country name, which is an inconsistency coming from Sanity.
     */
    IsoCountryCodeToCurrencyNameMap[sanityCountryNameToIso3(countryCode)]
  );
};

export const addDecimal = (amount: any) => {
  //@ts-ignore needed so TS doesn't complain about parseFloat
  return parseFloat(Math.round(amount * 100) / 10000).toFixed(2);
};

export const formatCurrency = (amount: number, countryCode: string = '') => {
  try {
    const currency = currencyName(countryCode);
    return formatMoneyBasedOnCurrency(amount, currency);
  } catch {
    return `⚠️ ${formatMoney(amount)}`;
  }
};

export const formatMoneyBasedOnCurrency = (amount: number, currency: string = '') => {
  try {
    const formattedCurrency = new Intl.NumberFormat('en', {
      style: 'currency',
      currency,
    }).format(centsToDollars(amount));
    return formattedCurrency;
  } catch {
    return `⚠️ ${formatMoney(amount)}`;
  }
};

const REPLACEABLE_VALUES = [null, false, undefined, ''];

export const formatDisplay = (item: any, replace = 'None') => {
  return !REPLACEABLE_VALUES.includes(item) ? String(item) : replace;
};

/**
 * formats with a hyphen every 4 characters
 * eg: "KFI2XAXBFDW2" -> "KFI2-XAXB-FDW2"
 */
export const formatLoyaltyCardId = (cardId: string) => cardId.replace(/(.{4})(?=.)/g, '$1-');

export const noop = () => {};

export const capitalize = (str: string) =>
  str.split(' ').reduce((acc, word, i, arr) => {
    const capitalizedWord = word[0].toUpperCase() + word.slice(1).toLowerCase();
    const notEnd = i < arr.length - 1;
    return acc + `${capitalizedWord}${notEnd ? ' ' : ''}`;
  }, '');

export const filtersObjectFromMapping = ({
  mapping,
  selected,
}: {
  mapping: Record<string, string> | Map<string, string>;
  selected: boolean;
}) => Object.keys(mapping).reduce((acc, status) => ({ ...acc, [status]: selected }), {});

export const nameOfMostExpensiveCartEntry = (order: any) =>
  [...(order?.cart?.cartEntries ?? [])].sort(
    (a, b) => (b?.price ?? 0 / (b?.quantity ?? 1)) - (a?.price ?? 0 / (a?.quantity ?? 1)),
  )[0]?.name;

export const filterOfferName = (offerNameFilter: string, offerName: string) => {
  return offerName.toLowerCase().includes(offerNameFilter.toLowerCase());
};

export const intlLoyaltyContext = (isoCountryCode: string) => {
  const customerRegion = ISO_ISO2_MAPPING[isoCountryCode as IsoCountryCode];
  const context = {
    headers: {
      'x-region': customerRegion,
    },
  };
  return context;
};

/** 
 * This functions rounds UP to the nearest half
 * 
 * @param {number} amonunt -> amount in decimal or integer
 * @returns {number} -> rounded UP to the nearest half
 * 
 * @example
 * roundUpToNearestHalf(3.28); // returns 3.5
 * roundUpToNearestHalf(3.89); // returns 4

*/
export const roundUpToNearestHalf = (amount: number): number => {
  const remainder = amount % 1;
  if (0 < remainder && remainder < 0.5) {
    return Math.floor(amount) + 0.5;
  }
  return remainder === 0.5 ? amount : Math.ceil(amount);
};

/**
 * Returns a boolean flag for each Refund status type
 * @param {OrderDetails} order
 * @returns {Object} { isRefundError, isRefundRequested, isRefundSuccess }
 */
export const checkRefundStatus = (order: OrderDetails | null) => {
  const orderStatus = order?.status ?? '';
  const paymentStatus = order?.paymentStatus ?? '';
  const isPaymentsV1 = order?.paymentVersion === PAYMENT_VERSION_MAPPING.V1;
  const isRefundError = isPaymentsV1
    ? REFUND_ERROR_PAYMENT_V1_STATUSES.includes(paymentStatus)
    : REFUND_ERROR_NON_PAYMENT_V1_ORDER_STATUSES.includes(orderStatus);
  const isRefundSuccess = isPaymentsV1
    ? REFUND_SUCCESS_PAYMENT_V1_STATUSES.includes(paymentStatus)
    : REFUND_SUCCESS_NON_PAYMENT_V1_ORDER_STATUSES.includes(orderStatus);
  const isRefundRequested = !isRefundError && !isRefundSuccess;

  return { isRefundError, isRefundRequested, isRefundSuccess };
};
