import { Dispatch, ReactNode, SetStateAction } from 'react';

export enum FilterIndices {
  Assigned = 0,
  Redeemed = 1,
  Expires = 2,
  Name = 3,
  AgentEmail = 4,
  Comments = 5,
  Reasons = 6,
  RefundOptions = 7,
  RemediationType = 8,
  Issued = 9,
  OrderStatus = 10,
  PaymentStatus = 11,
  DeliveryStatus = 12,
  ServiceMode = 13,
  LoyaltyChannel = 14,
  None = -1,
}

export type FilterProps = {
  label: string;
  filterIndex: FilterIndices;
  expandedField: FilterIndices;
  setExpandedField: Dispatch<SetStateAction<FilterIndices>>;
};

export enum ToggleState {
  Inactive = -1,
  Active = 1,
  Undefined = 0,
}

export type CheckboxFilterProps = FilterProps & {
  checkboxNames: string[];
  options: boolean[];
  setOptionsActivated: Dispatch<SetStateAction<boolean[]>>;
};

export type DateFilterProps = FilterProps & {
  startDate: string;
  setStartDate: Dispatch<SetStateAction<string>>;
  endDate: string;
  setEndDate: Dispatch<SetStateAction<string>>;
};

//We can convert this to the enum, and its children
export type FilterHeaderProps = {
  expanded: boolean;
  handlerExpanded: Dispatch<SetStateAction<boolean>>;
  title: string;
  children?: ReactNode;
};

//This prop is used for any filter component that has subrenders
export type FilterContainerProps = FilterProps & {
  children?: ReactNode;
};

export type TextFilterProps = FilterProps & {
  text: string;
  setText: Dispatch<SetStateAction<string>>;
};
