export class Trie {
  public children: Map<string, Trie>;
  private root = this;
  constructor() {
    this.children = new Map<string, Trie>();
  }

  //Adds an array of values into the trie
  public addAll(arr: string[]): void {
    arr.forEach((element) => this.add(element));
  }

  //Searches for an insertion point and then adds remaining tokens into trie
  public add(key: string): void {
    const items = key.split('.'); //Tokenization function
    let tempNode: Trie = this.root;
    let i = 0;
    //Iterates through existing values.
    while (i < items.length && tempNode.children.get(items[i]) !== undefined) {
      tempNode = tempNode.children.get(items[i]) ?? new Trie();
      i = i + 1;
    }
    //Adds non-existent values under tempNode
    while (i < items.length) {
      tempNode?.children.set(items[i], new Trie());
      tempNode = tempNode.children.get(items[i]) ?? new Trie();
      i = i + 1;
    }
  }
  //Finds the desired search string and returns the explicit subtrees.
  public search(key: string): string[] {
    const items = key.split('.');
    let tempNode: Trie = this.root;
    let i = 0;
    while (i < items.length && tempNode.children.get(items[i]) != null) {
      tempNode = tempNode.children.get(items[i]) ?? new Trie();
      i = i + 1;
    }
    return tempNode.get(items.slice(0, i).join('.')) ?? [];
  }

  //Stringifies all sub values into one flat list.
  public get(prefix: string): string[] {
    let output: string[] = [];
    if (this.children.size > 0) {
      this.children.forEach((value, key) => {
        output = [...output, ...value.get(prefix + '.' + key)];
      });
      return output;
    }
    return [prefix];
  }
}
