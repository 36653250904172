import { alpha, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  actions: {
    justifyContent: 'flex-end',
    borderTop: '1px solid rgba(224, 224, 224, 1)',
  },
  chipsWrapper: {
    display: 'flex',
    gap: theme.spacing(0.5),
    padding: theme.spacing(1, 0.5, 0),
    '& span': {
      userSelect: 'none',
    },
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  avatarText: {
    color: theme.palette.common.white,
  },
  icon: {
    fontSize: theme.typography.fontSize,
    verticalAlign: 'middle',
  },

  blink: {
    padding: '2px 3px',
    animation: `$blink 1s linear`,
  },
  default: {
    padding: '2px 3px',
  },
  '@keyframes blink': {
    from: { backgroundColor: alpha(theme.palette.primary.main, 0.5) },
    to: { backgroundColor: 'transparent' },
  },
}));
