import { LinearProgress } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    loader: {
      position: 'absolute',
      top: theme.spacing(7),
      right: 0,
      left: 0,
      [theme.breakpoints.up('sm')]: {
        top: theme.spacing(8),
      },
    },
  });
});

const Loader = () => {
  const classes = useStyles();
  return (
    <div className={classes.loader}>
      <LinearProgress />
    </div>
  );
};

export default Loader;
