import gql from 'graphql-tag';

export const OrderFragment = gql`
  fragment OrderFragment on Order {
    createdAt
    rbiOrderId
    posVendor
    posOrderId
    status
    cart {
      platform
    }
    delivery {
      assignedFleetName
      dropoff {
        beforeTime
      }
      driver {
        driverId
      }
      feeCents
      feeDiscountCents
      status
      tipCents
    }
    order {
      refund {
        primaryReason
        secondaryReason
        approvedAmount
        refundMethod
        refundedItems {
          name
          refundAmount
          refundQuantity
        }
      }
    }
    paymentStatus
    paymentVersion
    paymentAuditEvent {
      auditLogId
      createdAt
      eventId
      context {
        currency
        failureReason
        prepaid {
          balance {
            beginBalance
            currentBalance
          }
        }
      }
    }
  }
`;
