import gql from 'graphql-tag';

export const RecordNonRemediation = gql`
  mutation RecordNonRemediation(
    $customerId: String!
    $orderId: String!
    $remediationComment: String!
    $remediationReason: String!
  ) {
    recordNonRemediation(
      customerId: $customerId
      orderId: $orderId
      remediationComment: $remediationComment
      remediationReason: $remediationReason
    )
  }
`;
