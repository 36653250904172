import { CognitoUserSession } from 'amazon-cognito-identity-js';

import { userPool } from './cognito';

// amazon-cognito-identity-js types callback as Function
// but we can expect CognitoUserSession | null per the source code
const getSessionCallback = (err: Error | void, session: CognitoUserSession | null) => {
  if (err) {
    console.error(err.message);
  }
  return session;
};

// Returns the current user session from user pool
export const getCurrentUserSession = async (signOut?: () => void) => {
  const currentUser = userPool.getCurrentUser();

  if (!currentUser) {
    return null;
  }

  // cognito getSession will automatically refresh access token if its expired
  // this calls our callback but returns undefined when refreshing asynchronously, so we wrap it in a promise
  const cognitoUserSession: CognitoUserSession | null | undefined = await new Promise((resolve) =>
    currentUser.getSession((...args: Parameters<typeof getSessionCallback>) =>
      resolve(getSessionCallback(...args)),
    ),
  );

  if (cognitoUserSession) {
    return cognitoUserSession;
  }

  // If no session, it means the refresh token is expired
  signOut?.(); // eslint-disable-line no-unused-expressions
  return null;
};
