import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  connector: {
    backgroundColor: theme.palette.primary.main,
  },
  content: {
    flex: 1,
  },
  oppositeContent: {
    flex: 0,
  },
  oppositeContentAvatar: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    '& > *': {
      color: theme.palette.common.white,
    },
  },
  oppositeContentChip: {
    width: 250,
    color: theme.palette.common.black,
    backgroundColor: theme.palette.grey[400],
    '& span': {
      flexGrow: 1,
      textAlign: 'center',
    },
  },
  oppositeContentChipLink: {
    cursor: 'pointer',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  },
}));
