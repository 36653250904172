import gql from 'graphql-tag';

import {
  LoyaltyCardFragment,
  LoyaltyCustomerFragment,
  LoyaltyTransactionFragment,
} from './fragments';

export const GetLoyaltyByEmail = gql`
  query loyaltyByEmail($email: String!) {
    loyaltyByEmail(email: $email) {
      customer {
        ...LoyaltyCustomerFragment
      }
      loyaltyCard {
        ...LoyaltyCardFragment
        loyaltyTransactions {
          count
          transactions {
            ...LoyaltyTransactionFragment
          }
        }
      }
    }
  }
  ${LoyaltyCustomerFragment}
  ${LoyaltyCardFragment}
  ${LoyaltyTransactionFragment}
`;
