import gql from 'graphql-tag';

import {
  LoyaltyCardFragment,
  LoyaltyCustomerFragment,
  LoyaltyTransactionFragment,
} from './fragments';

export const GetLoyaltyByCustomerId = gql`
  query loyaltyByCustomerId(
    $customerId: String!
    $lastTransactionBarcode: String
    $lastTransactionId: String
    $lastTransactionTimestamp: String
    $pageSize: Int
  ) {
    loyaltyByCustomerId(customerId: $customerId) {
      customer {
        ...LoyaltyCustomerFragment
      }
      loyaltyCard(
        lastTransactionBarcode: $lastTransactionBarcode
        lastTransactionId: $lastTransactionId
        lastTransactionTimestamp: $lastTransactionTimestamp
        pageSize: $pageSize
      ) {
        ...LoyaltyCardFragment
        loyaltyTransactions {
          count
          transactions {
            ...LoyaltyTransactionFragment
          }
        }
      }
    }
  }
  ${LoyaltyCustomerFragment}
  ${LoyaltyCardFragment}
  ${LoyaltyTransactionFragment}
`;

export const GetCustomerRemediationReasons = gql`
  query GetCustomerRemediationReasons($customerId: String!) {
    Customer(customerId: $customerId) {
      id: customerId
      customerId
      isoCountryCode
      remediationReasons {
        label
        value
      }
    }
  }
`;

export const GetLoyaltyCustomerByCustomerId = gql`
  query loyaltyByCustomerId($customerId: String!) {
    loyaltyByCustomerId(customerId: $customerId) {
      customer {
        ...LoyaltyCustomerFragment
      }
      loyaltyCard {
        ...LoyaltyCardFragment
      }
    }
  }
  ${LoyaltyCustomerFragment}
  ${LoyaltyCardFragment}
`;
