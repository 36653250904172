import gql from 'graphql-tag';

export const updateEmailMutation = gql`
  mutation updateEmail($email: String!, $newEmail: String!, $region: String!) {
    updateEmail(email: $email, newEmail: $newEmail, region: $region) {
      email
      newEmail
      region
      success
    }
  }
`;
