import gql from 'graphql-tag';

export const ReplaceCard = gql`
  mutation prepaidsReplace($input: PrepaidsReplaceInput!) {
    prepaidsReplace(input: $input) {
      prepaid {
        balance
        cardNumber
      }
    }
  }
`;
