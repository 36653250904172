import React, { FC } from 'react';

import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider as MUThemeProvider } from '@material-ui/styles';
import { useIntl } from 'react-intl';

import { getBrandConfigs } from 'config/get-brand-configs';

import {
  WHITE,
  BACKGROUND_ACCENT,
  BACKGROUND_SECONDARY,
  GREY,
  LIGHT_GREY,
  SECONDARY_GREY,
  DARK_GREY,
  ACTIVE_GREY,
  PRIMARY_ACCENT,
  PRIMARY_BUTTON,
  LIGHT_RED,
  SECONDARY_RED,
  TIM_HORTONS_DARK_RED,
  LIGHT_GREEN,
  DARK_BLUE,
} from './colors';

export const palette = {
  background: {
    surface: WHITE,
    secondary: BACKGROUND_SECONDARY,
    accent: BACKGROUND_ACCENT,
  },
  green: {
    accent: LIGHT_GREEN,
  },
  grey: {
    accent: LIGHT_GREY,
    secondary: SECONDARY_GREY,
    main: GREY,
    raised: DARK_GREY,
    active: ACTIVE_GREY,
  },
  primary: {
    accent: PRIMARY_ACCENT,
    main: PRIMARY_BUTTON,
    raised: DARK_BLUE,
    contrastText: WHITE,
  },
  alert: {
    accent: LIGHT_RED,
    secondary: SECONDARY_RED,
    main: TIM_HORTONS_DARK_RED,
    raised: TIM_HORTONS_DARK_RED,
  },
};

export const ThemeProvider: FC = ({ children }) => {
  const { formatMessage } = useIntl();

  const { theme: brandTheme } = getBrandConfigs(formatMessage);

  const brandPalette = {
    primary: { main: brandTheme.brandColor },
  };

  const theme = createTheme({ palette: brandPalette });

  return <MUThemeProvider theme={theme}>{children}</MUThemeProvider>;
};
