import { Fade, Grid } from '@material-ui/core';
import { NoData } from 'design-system';

import Loader from 'components/loader';

import { TimelineWrapperProps } from './types';

const TimelineWrapper = ({
  breadcrumbs,
  summary,
  filters,
  timeline,
  loading,
  noData,
}: TimelineWrapperProps) => {
  return (
    <>
      {loading && <Loader />}
      <Fade in>
        <div>
          {breadcrumbs}

          {!noData || loading ? (
            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {summary}
                  </Grid>
                  <Grid item xs={12}>
                    {filters}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={8}>
                {timeline}
              </Grid>
            </Grid>
          ) : (
            <NoData />
          )}
        </div>
      </Fade>
    </>
  );
};

export { TimelineWrapper };
