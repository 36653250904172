import { ReactNode } from 'react';

import { Link as MUILink } from '@material-ui/core';
import { Link as RouterLink } from '@reach/router';

const TextLink = ({ children, to }: { children: ReactNode; to: string }) => {
  return (
    <MUILink
      component={RouterLink}
      to={to}
      style={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}
    >
      {children}
    </MUILink>
  );
};

export default TextLink;
