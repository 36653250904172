import gql from 'graphql-tag';

export const INTLLoyaltyUserFragment = gql`
  fragment INTLLoyaltyUserFragment on INTLLoyaltyUser {
    id
    points
    createdAt
    email
    name
    dateOfBirth
    cognitoId
  }
`;
