import { FC } from 'react';

import { ListItem, ListItemIcon, ListItemText, Tooltip } from '@material-ui/core';
import { Link } from '@reach/router';
import { useIntl } from 'react-intl';

interface ISidebarItem {
  drawerOpen?: boolean;
  route: string;
  textId: string;
  icon?: React.ReactNode;
}

export const SidebarItem: FC<ISidebarItem> = ({ drawerOpen, route, textId, icon }) => {
  const { formatMessage } = useIntl();

  const listItem = (
    <ListItem button component={Link} to={route}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={formatMessage({ id: textId })} />
    </ListItem>
  );

  return drawerOpen ? (
    listItem
  ) : (
    <Tooltip placement="right" title={formatMessage({ id: textId })}>
      {listItem}
    </Tooltip>
  );
};
