import React from 'react';

function PLK() {
  return (
    <svg viewBox="0 0 124 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.8712 0.844116C59.3139 0.844116 55.4664 4.87674 55.4664 10.4323C55.4664 15.9878 59.0904 20.1218 65.4598 20.1218C68.3083 20.1218 70.8005 19.4301 72.7985 17.8793L70.5525 13.7681C69.0855 14.7863 67.5067 15.3591 65.7025 15.3591C63.3046 15.3591 61.89 14.2641 61.3486 12.3621H73.6001C73.7049 11.8103 73.7975 10.7362 73.7887 9.78085C73.7556 5.82682 71.672 0.844116 64.8712 0.844116ZM61.3014 8.4675C61.7503 6.61623 62.9274 5.35353 64.8887 5.35353C66.7574 5.35353 68.1092 6.44508 68.2646 8.4675H61.3014Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M85.2578 1.52008L81.6496 9.29715L78.1951 1.52008H71.9514L78.6177 14.8073V19.8808H84.6099V14.8143L91.622 1.52008H85.2578Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.36839 0.613614C5.22023 0.512319 1.55961 0.713164 0 0.828431L0.125746 5.47581L1.61899 5.3815V19.8598H7.37538V16.2429C15.7864 15.8412 17.753 11.3545 17.753 8.18984C17.7512 3.70139 14.0923 0.929727 7.36839 0.613614ZM7.37538 11.73V5.40421C11.043 5.57711 11.925 7.14545 11.925 8.42387C11.9232 10.2402 10.7059 11.6618 7.37538 11.73Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M98.7214 0.844116C93.1641 0.844116 89.3167 4.87674 89.3167 10.4323C89.3167 15.9878 93.0262 20.1218 99.2594 20.1218C101.856 20.1218 104.113 19.5559 106.006 18.2722L103.935 14.0126C102.579 14.8841 101.156 15.3574 99.5021 15.3574C97.2072 15.3574 95.7681 14.2623 95.2093 12.3604H107.449C107.553 11.8085 107.649 10.7344 107.641 9.77911C107.604 5.82682 105.522 0.844116 98.7214 0.844116ZM95.1516 8.4675C95.6005 6.61623 96.7776 5.35353 98.7389 5.35353C100.608 5.35353 101.973 6.46429 102.115 8.4675H95.1516Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.9384 0.959473C22.6518 0.959473 18.2542 4.88556 18.2542 10.4533C18.2542 16.0211 22.6518 19.9472 27.9384 19.9472C33.225 19.9472 37.6227 16.0229 37.6227 10.4533C37.6227 4.88556 33.225 0.959473 27.9384 0.959473ZM27.9821 14.8545C25.413 14.8545 23.7853 12.8792 23.7853 10.4184C23.7853 7.95761 25.4148 5.9806 27.9821 5.9806C30.5512 5.9806 32.1789 7.95587 32.1789 10.4184C32.1789 12.8774 30.5512 14.8545 27.9821 14.8545Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.7238 0.803983C42.5966 0.704434 38.9709 0.901786 37.4288 1.01705L37.5528 5.61903L39.032 5.52646V19.8616H44.7308V16.2795C53.0598 15.8813 55.0053 11.44 55.0053 8.30686C55.0036 3.86032 51.3814 1.1166 44.7238 0.803983ZM44.7291 11.8103V5.54567C48.36 5.71683 49.2332 7.26945 49.2332 8.53565C49.2332 10.3363 48.0282 11.7422 44.7291 11.8103Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M117.692 8.95487C116.665 8.54095 115.428 8.08338 114.623 7.71662C113.835 7.35859 113.558 7.00231 113.558 6.45915C113.558 5.7396 114.302 5.34664 115.248 5.34664C116.698 5.34664 117.83 6.01555 118.846 7.10186L122.037 3.78355C120.669 1.94101 118.32 0.779602 115.322 0.779602C111.259 0.779602 108.09 2.73915 108.09 6.60411C108.09 9.65695 109.562 11.124 112.417 12.3605C113.228 12.7115 114.312 13.0224 115.124 13.3682C115.833 13.6686 116.279 13.9882 116.279 14.5698C116.279 15.1095 115.806 15.5478 114.435 15.5478C113.17 15.5478 111.348 15.0396 110.023 14.1786L108.083 18.1536C109.929 19.4896 112.159 20.038 114.44 20.038C117.982 20.038 121.778 18.5046 121.778 14.3812C121.778 11.2777 119.791 9.80191 117.692 8.95487Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default PLK;
