import { makeStyles } from '@material-ui/core';
export const useStyles = makeStyles(() => ({
  switch: {
    color: 'inherit',
    flexGrow: 0,
    padding: '0 10px',
  },
  childRowContent: {
    maxHeight: 300,
    overflow: 'auto',
  },
  gridItem: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  expandButton: {
    margin: '0 10px',
    flexGrow: 0,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    padding: '10px',
  },
  headerText: {
    cursor: 'pointer',
    height: '100%',
    flexGrow: 1,
    padding: '0 10px',
  },
}));

export const useChipStyles = makeStyles(() => ({
  avatar: {
    color: 'inherit',
    background: 'transparent',
  },
  chip: {
    color: 'inherit',
    marginBottom: '5px',
    fontWeight: 'normal',
    marginRight: '10px',
    '&:last-of-type': {
      marginRight: '0',
    },
  },
  chips: {
    color: 'inherit',
    padding: '0 60px',
  },
}));

export const useFilterDetailsStyles = makeStyles((theme) => ({
  filterDetails: {
    padding: '10px 20px',
    backgroundColor: theme.palette.grey[100],
  },
}));
