import { createContext, FC, useContext } from 'react';

import { IntlProvider as ReactIntlProvider } from 'react-intl';

import useIntl from 'state/intl/hook';
import { ICreditCard } from 'types';

export interface IIntlProviderContext {
  localeLanguage: string;
  setCurrentLocale: Function;
  messages: Record<string, string>;
  formattedDate: (date: Date, options?: Intl.DateTimeFormatOptions) => string;
  formattedTime: (date: Date, options?: Intl.DateTimeFormatOptions) => string;
  formattedDateTime: (date: Date | string, options?: Intl.DateTimeFormatOptions) => string;
  shortDate: (date: Date, options?: Intl.DateTimeFormatOptions) => string;
  shortDateTime: (date: Date, options?: Intl.DateTimeFormatOptions) => string;
  formatCreditCards: (creditCards: ICreditCard[]) => object;
}

export const defaultLocale = 'en-US';

export const IntlContext = createContext<IIntlProviderContext>({} as IIntlProviderContext);

export const useLocale = () => useContext(IntlContext);

export const IntlProvider: FC = ({ children }) => {
  const value = useIntl();

  const { localeLanguage, messages } = value;

  return (
    <IntlContext.Provider value={value}>
      <ReactIntlProvider defaultLocale={defaultLocale} messages={messages} locale={localeLanguage}>
        {children}
      </ReactIntlProvider>
    </IntlContext.Provider>
  );
};
