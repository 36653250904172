import { FC, useCallback, useState } from 'react';

import {
  Drawer,
  AppBar,
  Toolbar,
  List,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  Collapse,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import {
  Menu,
  ChevronLeft,
  ChevronRight,
  ExitToApp,
  Warning,
  ExpandLess,
  ExpandMore,
  Language,
  Home,
  Person,
} from '@material-ui/icons';
import { navigate, Link } from '@reach/router';
import clsx from 'clsx';
import { useIntl } from 'react-intl';

import AuthRequired from 'components/auth/auth-required';
import Logo from 'components/logos';
import RestrictedComponent from 'components/restricted-component';
import { getBrandConfigs } from 'config/get-brand-configs';
import { useAuthContext } from 'state/auth';
import { useParamsContext } from 'state/params';
import Permission from 'types/permissions';
import { routes, expandPath } from 'utils/routing';

import { useStyles } from './layout.styles';
import { SidebarItem } from './sidebar-item';
import { UniversalSearch } from './universal-search';

const Layout: FC = ({ children }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { email, signOut, roles } = useAuthContext();
  const { params } = useParamsContext();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [userOpen, setUserOpen] = useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleUserClick = () => {
    setUserOpen(!userOpen);
  };

  const handleSignOut = useCallback(async () => {
    await signOut();
    navigate(routes.signIn);
  }, [signOut]);

  const { formatMessage } = useIntl();

  return (
    <div className={classes.root}>
      <AuthRequired>
        <>
          <AppBar
            position="fixed"
            variant="outlined"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: drawerOpen,
            })}
          >
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, {
                  [classes.hide]: drawerOpen,
                })}
              >
                <Menu />
              </IconButton>

              <Link to={routes.default} className={classes.logo}>
                <Logo />
              </Link>
              <UniversalSearch />
            </Toolbar>
          </AppBar>

          <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: drawerOpen,
              [classes.drawerClose]: !drawerOpen,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: drawerOpen,
                [classes.drawerClose]: !drawerOpen,
              }),
            }}
          >
            <div className={classes.toolbar}>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'rtl' ? <ChevronRight /> : <ChevronLeft />}
              </IconButton>
            </div>

            <Divider />

            <List>
              <SidebarItem
                drawerOpen={drawerOpen}
                route={routes.default}
                textId="sidebar.home"
                icon={<Home />}
              />
            </List>

            <Divider />

            <List>
              {getBrandConfigs(formatMessage).links.map(({ name, route, Icon }) => {
                const isOffersRoute = route.startsWith(routes.offers);
                const resolvedRoute = isOffersRoute ? routes.offers : route;
                const targetPath = expandPath(resolvedRoute, params);

                return (
                  <SidebarItem
                    key={name}
                    drawerOpen={drawerOpen}
                    route={targetPath}
                    textId={name}
                    icon={<Icon />}
                  />
                );
              })}
            </List>

            <Divider />

            <List>
              <ListItem button onClick={handleUserClick} disabled={!drawerOpen}>
                <ListItemIcon>
                  <Avatar className={classes.avatar}>{email?.[0]}</Avatar>
                </ListItemIcon>
                <ListItemText className={classes.email} primary={email?.split('@')?.[0]} />
                {userOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={userOpen && drawerOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {(roles as string[]).map((role) => {
                    return (
                      <ListItem
                        button
                        className={classes.nested}
                        key={role}
                        component={Link}
                        to={`/permissions/${role}`}
                      >
                        <ListItemIcon>
                          <Language />
                        </ListItemIcon>
                        <ListItemText primary={role} />
                      </ListItem>
                    );
                  })}
                  {roles.length === 0 && (
                    <ListItem className={classes.nested}>
                      <ListItemIcon>
                        <Warning />
                      </ListItemIcon>
                      <ListItemText primary={formatMessage({ id: 'role.noRolesAssigned' })} />
                    </ListItem>
                  )}
                </List>
              </Collapse>
            </List>

            <Divider />

            <List>
              <RestrictedComponent accessPermission={Permission.AuditAccount}>
                <ListItem button component={Link} to={routes.adminPanel} disabled={!drawerOpen}>
                  <ListItemIcon>
                    <Person />
                  </ListItemIcon>
                  <ListItemText primary={formatMessage({ id: 'sidebar.adminPanel' })} />
                </ListItem>
              </RestrictedComponent>

              <ListItem button onClick={handleSignOut}>
                <ListItemIcon color="red">
                  <ExitToApp />
                </ListItemIcon>
                <ListItemText primary={formatMessage({ id: 'sidebar.signOut' })} />
              </ListItem>
            </List>

            <Divider />
          </Drawer>
        </>
      </AuthRequired>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
};

export default Layout;
