import { setContext } from 'apollo-link-context';
import { merge } from 'lodash-es';

export const withHeaders = (
  headersToMerge:
    | { [key: string]: string }
    | ((previousContext: Record<string, any>) => { [key: string]: string }),
) =>
  setContext((_, previousContext) => {
    const headers =
      headersToMerge instanceof Function ? headersToMerge(previousContext) : headersToMerge;

    return merge({ headers }, previousContext);
  });
