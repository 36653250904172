import { ApolloLink } from 'apollo-link';

const TYPENAME_KEY = '__typename';

const stripTypename = <O>(data: O): O => {
  for (const key in data) {
    if (key === TYPENAME_KEY) {
      delete data[key];
    }

    if (typeof data[key] === 'object') {
      stripTypename(data[key]);
    }
  }

  return data;
};

// removes the "__typename" field from data being sent as graphql
// input to avoid errors when sending graphql data as an input to
// another mutation
export const stripTypenameLink = new ApolloLink((request, forward) => {
  if (request.variables) {
    request.variables = stripTypename(request.variables);
  }
  return forward ? forward(request) : null;
});
