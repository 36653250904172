import { Brand as UppercaseBrands } from '@rbilabs/intl';
import { LANGUAGES } from 'components/language-select/types';

export type Brand = Lowercase<UppercaseBrands>;

type LanguagesKeys = keyof typeof LANGUAGES;

export type SupportedLanguages = typeof LANGUAGES[LanguagesKeys];

export interface IClasses {
  [k: string]: string;
}
export interface IAction<ActionType = string> {
  type: ActionType;
  icon: Icons;
}

export interface IOptionBase<ActionType = unknown> {
  label: string;
  value?: string;
  formatDisplay?: (
    item: any,
    externalResource?: { [k: string]: any },
  ) => string | number | Date | JSX.Element;
  valueGetter?: string;
  actions?: IAction<ActionType>[];
}

export interface IMap<T> {
  [k: string]: T;
}

export enum Icons {
  GREEN_CHECK = 'GREEN_CHECK',
  RIGHT_ARROW = 'RIGHT_ARROW',
  EDIT = 'EDIT',
}

export * from './customer';
export * from './offer';
export * from './order';
export * from './store';
export * from './tier';
export { IsoCountryCode2Char } from '@rbilabs/intl-common';
